<template>
  <section class="py-20 px-6 text-white">
    <div class="container mx-auto">
      <div class="flex flex-col-reverse lg:flex-row">
        <div class="lg:pr-10">
          <h3 class="hidden lg:block text-4xl font-bold">Have any of these pain points? 👉</h3>
          <p class="mt-10 text-lg">Whether you are new to Follow Up Boss or a veteran user, if you are looking for a curated and intuitive approach to using your CRM then you've come to the right place. We're here to help ensure you're utilizing Follow Up Boss to its fullest potential. We offer a thorough setup comprised of smart lists, action plans, and automations that provide a simplified workflow and enable you to stay focused on what you do best: creating relationships and closing deals.</p>
        </div>
        <div class="bg-white text-black p-5 rounded-xl flex-shrink-0 font-medium">
          <p v-for="item, idx in painPoints" :key="`pain_point_${idx}`" class="py-3" :class="{ 'border-t': idx > 0 }">{{ item }}</p>
        </div>
        <h3 class="lg:hidden text-center text-4xl font-bold mb-10">Do any of these sound familiar?</h3>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  computed: {
    painPoints() {
      return [
        "I have a CRM and a separate system for transaction checklists",
        "I have staff or team members who want to know where transactions stand",
        "I need to become more efficient",
        "I struggle with keeping all my transactions straight",
        "I have too many software subscriptions",
        "I am entering data in multiple places",
        "I am feeling like things are falling through the cracks",
        "I’m worried I’ll break something",
        "I’m struggling with SOPs for transactions within my company"
      ]
    }
  }
}
</script>