<template>
  <FormPageLayout>
    <p class="text-2xl font-semibold text-site-purple">Thanks for signing up!</p>
    <p class="text-lg mt-5">We will need the following information before we can start revamping your FUB.</p>
    <p class="font-medium text-sm mt-2">PLEASE NOTE: We do not provide Spanish translations for our lender revamp at
      this time.</p>
    <!-- Basic Info -->
    <FormSection v-if="!keeId" title="Your Info">
      <VTextField :v="v$.formModel.basicInfo.firstName" placeholder="First Name" />
      <VTextField :v="v$.formModel.basicInfo.lastName" placeholder="Last Name" />
      <VTextField :v="v$.formModel.basicInfo.email" placeholder="Email" />
      <VTextField :v="v$.formModel.basicInfo.phone" placeholder="Phone Number" />
    </FormSection>
    <FormSection title="Who will the setup be enabled for?">
      <div class="field" :class="{ 'field-block-error': v$.formModel.setupEnabledFor.$error }">
        <p v-if="v$.formModel.setupEnabledFor.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.setupEnabledFor.$errors[0].$message }}
        </p>
        <input type="radio" value="All users" v-model="formModel.setupEnabledFor" />
        <label>All users within the FUB account <span class="text-gray-600 text-sm">(This option is the default behavior
            of our setup and is recommended for most teams and solo agents.)</span></label>
        <br />
        <input type="radio" value="Only me or a limited set of users" v-model="formModel.setupEnabledFor" />
        <label>Only me OR a limited set of users in the FUB account <span class="text-gray-600 text-sm">(This option is
            a customization to our default setup and is an additional ${{ customUserEnablingPricing }} for the custom
            work. Any changes to this after the setup is complete will be billed as hourly work.)</span></label>
      </div>
    </FormSection>
    <!-- Follow Up Boss Credentials -->
    <FormSection title="Follow Up Boss Credentials" helperText="(Owner Account)">
      <VTextField :v="v$.formModel.crmUsername" placeholder="Username" autocomplete="off" />
      <VTextField :v="v$.formModel.crmPassword" placeholder="Password" autocomplete="off" />
    </FormSection>
    <!-- Website URL(s) -->
    <FormSection title="Website URL">
      <div v-for="(website, idx) in formModel.websites" :key="`website_${idx}`">
        <input type="text" v-model="website.url" class="form-input" placeholder="http://mywebsite.com" />
        <font-awesome-icon v-if="idx !== 0" icon="fa-solid fa-times" class="cursor-pointer ml-2"
          @click="removeWebsite(idx)" />
      </div>
      <p class="mt-5 font-semibold text-sm">Have multiple websites?</p>
      <a @click="addWebsite" class="text-site-purple font-semibold mt-1 inline-block underline cursor-pointer">
        <font-awesome-icon icon="fa-solid fa-plus-circle" />
        Add Website
      </a>
    </FormSection>
    <!-- Online Reviews -->
    <FormSection title="Online Reviews"
      helperText="(Please provide any URLs to your online reviews i.e Google, Facebook etc.)">
      <div v-for="(onlineReview, idx) in formModel.onlineReviews" :key="`online_review_${idx}`">
        <input type="text" v-model="onlineReview.url" class="form-input" placeholder="http://review-url.com" />
        <font-awesome-icon v-if="idx !== 0" icon="fa-solid fa-times" class="cursor-pointer ml-2"
          @click="removeOnlineReview(idx)" />
      </div>
      <p class="mt-5 font-semibold text-sm">Have more online review URLs?</p>
      <a @click="addOnlineReview" class="text-site-purple font-semibold mt-1 inline-block underline cursor-pointer">
        <font-awesome-icon icon="fa-solid fa-plus-circle" />
        Add Online Review URL
      </a>
    </FormSection>
    <!-- YouTube Channel -->
    <FormSection title="YouTube Channel">
      <VTextField :v="v$.formModel.youtube" placeholder="YouTube Channel URL" />
    </FormSection>
    <!-- Company Name -->
    <FormSection title="Company Name">
      <VTextField :v="v$.formModel.companyName" placeholder="Company Name" />
    </FormSection>
    <!-- Business Address -->
    <FormSection title="Business Address">
      <VTextField :v="v$.formModel.businessAddress.addressLine1" placeholder="Address Line 1" />
      <VTextField :v="v$.formModel.businessAddress.addressLine2" placeholder="Address Line 2" />
      <CountrySelect v-model="formModel.businessAddress.country"
        :errorMessage="v$.formModel.businessAddress.country.$error ? v$.formModel.businessAddress.country.$errors[0].$message : ''" />
      <VTextField :v="v$.formModel.businessAddress.city" placeholder="City" />
      <StateSelect v-model="formModel.businessAddress.state"
        :errorMessage="v$.formModel.businessAddress.state.$error ? v$.formModel.businessAddress.state.$errors[0].$message : ''"
        :showProvinces="formModel.businessAddress.country === 'Canada'" />
      <VTextField :v="v$.formModel.businessAddress.zip"
        :placeholder="formModel.businessAddress.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
    </FormSection>
    <!-- Servicing Multiple Markets -->
    <FormSection title="Do you service more than one market?" helperText="(multiple states/provinces, regions)">
      <VRadio :v="v$.formModel.multiMarketServicing" />
    </FormSection>
    <!-- Pixel -->
    <FormSection title="Is the Follow Up Boss Pixel installed on your website?">
      <VRadio :v="v$.formModel.fubPixelInstalled" :options="['Yes', 'No', 'Not compatible']" />

      <FormSection v-if="formModel.fubPixelInstalled === 'No'"
        title="Courtesy service for installing the Follow Up Boss Pixel" :embed="true">
        <p class="text-sm text-gray-700">If you have a website backend and would like us to attempt the installation of
          the Follow Up Boss pixel for you, please provide your site's credentials below.<br><br><strong>Please
            note</strong> we offer this install service as a courtesy only and is not an objective step in the FUB
          revamp
          service we provide.<br><br><strong>Please note</strong> not every website is compatible with the Follow Up
          Boss
          Pixel and some site providers even reject the install of 3rd party scripts. Often we don't know compatibility
          issues until we attempt to install.<br><br>If you prefer to install the FUB pixel yourself you can refer to <a
            class="link" href="https://help.followupboss.com/hc/en-us/sections/1500001949321-Follow-Up-Boss-Pixel"
            target="_blank">Follow Up Boss Pixel documentation</a></p>
        <div v-for="(websiteBackend, idx) in formModel.websiteBackends" :key="`website_backend_${idx}`"
          class="bg-gray-100 p-5 pt-3 mt-5">
          <div v-if="idx !== 0" class="flex justify-end">
            <span class="cursor-pointer" @click="removeBackend(idx)">
              <font-awesome-icon icon="fa-solid fa-times" /> Remove
            </span>
          </div>
          <input type="text" class="form-input" v-model="websiteBackend.url" placeholder="Backend URL" />
          <br>
          <input type="text" class="form-input" v-model="websiteBackend.username" placeholder="Username" />
          <br>
          <input type="text" class="form-input" v-model="websiteBackend.password" placeholder="Password" />
        </div>
        <p class="mt-5 font-semibold text-sm">
          Need the FUB pixel added to multiple websites?
        </p>
        <a @click="addBackend" class="
          text-site-purple
          font-semibold
          mt-1
          inline-block
          underline
          cursor-pointer
        ">
          <font-awesome-icon icon="fa-solid fa-plus-circle" />
          Add Website
        </a>
      </FormSection>
    </FormSection>
    <!-- Stage setup -->
    <FormSection title="Setting up stages for the revamp">
      <p><span class="link underline text-base font-normal" @click="showStagesModal = true"><font-awesome-icon
            icon="fa-solid fa-info-circle" /> see stage definitions</span></p>
      <div class="field" :class="{ 'field-block-error': v$.formModel.stageSetup.$error }">
        <p v-if="v$.formModel.stageSetup.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.stageSetup.$errors[0].$message }}
        </p>
        <input type="radio" :value="stageSetupTypes.DEFAULT" v-model="formModel.stageSetup" />
        <label>Use the default stages provided by the revamp (recommended for most)</label>
        <br />
        <input type="radio" :value="stageSetupTypes.CUSTOM" v-model="formModel.stageSetup" />
        <label>I would like to opt out of using the default stages used by the revamp. Instead, I have a requirement
          that the revamp be customized to use predefined stages that I will provide to Kee Technology Solutions. I
          acknowledge that I must provide names of my stages before work on the revamp can be started and <strong>I
            agree to pay a ${{ customStagesPricing }} customization fee.</strong> I acknowledge that my review is
          required before going live with the revamp. (Note: We will reach out to you after your submission to obtain a
          list of stages we can use. We will reach back out after the setup is complete to review the setup with you
          before we go live with it.)</label>
      </div>
      <StagesModal v-if="showStagesModal" @close="showStagesModal = false" />
    </FormSection>
    <!-- SMS Add On -->
    <FormSection title="Include add-on SMS Drips?" :helperText="`(Additional $${pricing.SMS_DRIPS.toLocaleString('en')})`" chipText="SMS Add-On">
      <p class="mt-2 text-sm font-medium">If you currently use either <strong>Texting Betty</strong>, <strong>Leadngage</strong>, or <strong>StreetText</strong> for automated texting in FUB, we can add our SMS Drips to the setup we provide for an additional ${{ pricing.SMS_DRIPS }}.</p>
      <p class="mt-2 text-sm font-medium">Note: You can always sign up for this add-on at a later time if, in the future, you sign up for either Texting Betty, Leadngage or StreetText.</p>
      <p class="mt-2 text-sm font-medium">By making this selection you agree that you <strong>currently</strong> are using either <strong>Texting Betty</strong>, <strong>Leadngage</strong>, or <strong>StreetText</strong>.</p>
      <ToggleSwitch v-model="formModel.includeSMSDrips" />

      <FormSection v-if="formModel.includeSMSDrips" :embed="true" title="Texting automation service you use">
        <VRadio :v="v$.formModel.textingService" :options="['Texting Betty', 'Leadngage', 'StreetText']" />
      </FormSection>
    </FormSection>
    <!-- Setup activation notice -->
    <FormSection title="Activating your new setup">
      <p class="mt-5">After the setup of your new action plans and automations is complete, we will notify you via
        email. At this stage, you can either approve immediate activation or opt for a review period to evaluate the
        setup before it goes live.</p>
    </FormSection>
    <!-- Project Price -->
    <FormSection title="Project Price">
      <p class="text-lg font-medium mt-2">{{ totalDollarAmount }}</p>
      <p class="mt-2"><span class="link underline text-base font-normal"
          @click="showPriceBreakdownModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see price
          breakdown</span></p>
    </FormSection>
    <!-- Payment -->
    <FormSection title="Payment">
      <p class="font-semibold mt-5">
        Amount: {{ totalDollarAmount }} <span class="text-sm font-normal">(USD)</span>
      </p>
      <p class="font-semibold mt-5">Billing Information</p>
      <VTextField :v="v$.formModel.billing.firstName" placeholder="First Name" />
      <VTextField :v="v$.formModel.billing.lastName" placeholder="Last Name" />
      <VTextField :v="v$.formModel.billing.addressLine1" placeholder="Address Line 1" />
      <VTextField :v="v$.formModel.billing.addressLine2" placeholder="Address Line 2" />
      <CountrySelect v-model="formModel.billing.country"
        :errorMessage="v$.formModel.billing.country.$error ? v$.formModel.billing.country.$errors[0].$message : ''" />
      <VTextField :v="v$.formModel.billing.city" placeholder="City" />
      <StateSelect v-model="formModel.billing.state"
        :errorMessage="v$.formModel.billing.state.$error ? v$.formModel.billing.state.$errors[0].$message : ''"
        :showProvinces="formModel.billing.country === 'Canada'" />
      <VTextField :v="v$.formModel.billing.zip"
        :placeholder="formModel.billing.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
      <VTextField :v="v$.formModel.billing.phone" placeholder="Phone" />
      <p class="mt-5 font-semibold">Card Information</p>
      <SquarePayment ref="squarePayment" />
      <div class="field" :class="{ 'field-block-error': v$.formModel.agreeToConditions.$error }">
        <p v-if="v$.formModel.agreeToConditions.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.agreeToConditions.$errors[0].$message }}
        </p>
        <input type="checkbox" v-model="formModel.agreeToConditions" true-value="agree" false-value="" />
        <label class="ml-2 font-medium">I agree to
          <a @click="showConditionAgreement = true" class="link">these conditions</a></label>
      </div>
      <button @click="submit" :disabled="!canSubmit" class="button mt-5">
        Submit and Pay {{ totalDollarAmount }}
      </button>
      <p v-if="v$.$error" class="mt-2 text-red-500">
        Oops... There is an issue with the form. Check fields for errors.
      </p>
    </FormSection>
    <PriceBreakdownModal v-if="showPriceBreakdownModal" @close="showPriceBreakdownModal = false" :pricing="pricing"
      :pricingMeta="pricingMeta" />
    <ConditionAgreementModal v-if="showConditionAgreement" @close="showConditionAgreement = false" type="lender" />
    <SubmittingModal v-if="isSubmitting" @close="(isSubmitting = false)" :loading="loading" :errorMessage="error"
      :showNextSteps="true" :receiptUrl="receiptUrl">
      <template #success>
        <div>
          <p class="text-3xl text-site-purple font-semibold">Sign up complete!</p>
          <p class="text-xl pt-5">Your information has been submitted successfully and the payment of <strong
              class="font-semibold">{{ totalDollarAmount }}</strong> has been processed.</p>
        </div>
      </template>
    </SubmittingModal>
  </FormPageLayout>
</template>
<script>
import { required, requiredIf, formValidationMixin } from '@/mixins/formValidateMixin'
import _pick from 'lodash/pick'
import StagesModal from '../setup-form/StagesModal'
import { STAGE_SETUP_TYPES, SETUP_TYPE } from '../../../constants'
import { getPricing } from '@/pricing'

const newWebsiteBackend = {
  url: "",
  username: "",
  password: "",
};
const newWebsite = {
  url: "",
};
const newOnlineReview = {
  url: ""
};
export default {
  mixins: [formValidationMixin],
  components: { StagesModal },
  props: ["keeId"],
  data() {
    return {
      loading: false,
      error: "",
      isSubmitting: false,
      showConditionAgreement: false,
      showStagesModal: false,
      showPriceBreakdownModal: false,
      receiptUrl: "",
      formModel: {
        basicInfo: {
          firstName: "",
          lastName: "",
          email: "",
          phone: ""
        },
        setupEnabledFor: "",
        crmUsername: "",
        crmPassword: "",
        websites: [{ ...newWebsite }],
        onlineReviews: [{ ...newOnlineReview }],
        youtube: "",
        companyName: "",
        businessAddress: {
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        multiMarketServicing: "",
        fubPixelInstalled: "",
        websiteBackends: [{ ...newWebsiteBackend }],
        stageSetup: "",
        includeSMSDrips: false,
        textingService: "",
        agreeToConditions: "",
        billing: {
          firstName: "",
          lastName: "",
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
        },
      },
    };
  },
  validations() {
    const requiredIfNoKeeId = { required: requiredIf(!this.keeId) }
    return {
      formModel: {
        basicInfo: {
          firstName: requiredIfNoKeeId,
          lastName: requiredIfNoKeeId,
          email: requiredIfNoKeeId,
          phone: requiredIfNoKeeId
        },
        setupEnabledFor: { required },
        crmUsername: { required },
        crmPassword: { required },
        youtube: {},
        companyName: { required },
        businessAddress: {
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
        },
        multiMarketServicing: { required },
        fubPixelInstalled: { required },
        stageSetup: { required },
        textingService: { required: requiredIf(this.formModel.includeSMSDrips) },
        agreeToConditions: { required },
        billing: {
          firstName: { required },
          lastName: { required },
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
          phone: {}
        },
      },
    };
  },
  computed: {
    pricing() {
      return getPricing(this.setupType, this.legacyPricing)
    },
    legacyPricing() {
      return this.$route.query.legacyPricing || ''
    },
    pricingMeta() {
      return {
        customizeStages: this.customizeStages,
        customUserEnabling: this.customUserEnabling,
        includeSMSDrips: this.formModel.includeSMSDrips
      }
    },
    totalAmount() {
      let amount = this.pricing.BASE
      if (this.customUserEnabling) {
        amount += this.customUserEnablingPricing
      }
      if (this.customizeStages) {
        amount += this.pricing.CUSTOM_STAGES
      }
      if (this.formModel.includeSMSDrips) {
        amount += this.smsDripsPricing
        if (this.customizeStages) {
          amount += this.pricing.SMS_DRIPS_CUSTOM_STAGES
        }
      }
      return amount
    },
    totalDollarAmount() {
      return `$${this.totalAmount.toLocaleString('en')}`
    },
    smsDripsPricing() {
      return this.pricing.SMS_DRIPS
    },
    customStagesPricing() {
      return this.pricing.CUSTOM_STAGES
    },
    customUserEnablingPricing() {
      return this.pricing.CUSTOM_USER_ENABLING
    },
    customizeStages() {
      return this.formModel.stageSetup === STAGE_SETUP_TYPES.CUSTOM
    },
    canSubmit() {
      return !this.isSubmitting;
    },
    noFubPixel() {
      return this.formModel.fubPixelInstalled === "No";
    },
    stageSetupTypes() {
      return STAGE_SETUP_TYPES
    },
    customUserEnabling() {
      return !!this.formModel.setupEnabledFor && this.formModel.setupEnabledFor !== 'All users'
    },
    setupType() {
      return SETUP_TYPE.FUB_LENDER_REVAMP
    }
  },
  watch: {
    noFubPixel(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.formModel.websiteBackends = [{ ...newWebsiteBackend }];
      }
    },
  },
  methods: {
    openRouteInNewWindow(routeName) {
      window.open(this.$router.resolve({ name: routeName }).href, '_blank')
    },
    addWebsite() {
      this.formModel.websites.push({ ...newWebsite });
    },
    removeWebsite(idx) {
      this.formModel.websites.splice(idx, 1);
    },
    addOnlineReview() {
      this.formModel.onlineReviews.push({ ...newOnlineReview });
    },
    removeOnlineReview(idx) {
      this.formModel.onlineReviews.splice(idx, 1);
    },
    addBackend() {
      this.formModel.websiteBackends.push({ ...newWebsiteBackend });
    },
    removeBackend(idx) {
      this.formModel.websiteBackends.splice(idx, 1);
    },
    buildNotes() {
      return _pick(this.formModel, [
        'setupEnabledFor',
        'websites',
        'onlineReviews',
        'youtube',
        'companyName',
        'businessAddress',
        'multiMarketServicing',
        'fubPixelInstalled',
        'websiteBackends',
        'stageSetup',
        'includeSMSDrips',
        'textingService'
      ])
    },
    async submit() {
      if (!(await this.v$.$validate())) return

      try {
        const notes = this.buildNotes();
        const billing = this.formModel.billing;
        const { locationId, sourceId } = await this.$refs.squarePayment.tokenize();
        this.error = ""
        this.isSubmitting = true
        this.loading = true

        const { receiptUrl } = await this.$service.createSetup({
          basicInfo: !this.keeId ? this.formModel.basicInfo : null,
          keeId: this.keeId || null,
          crmPassword: this.formModel.crmPassword,
          crmUsername: this.formModel.crmUsername,
          notes,
          meta: {
            setupType: this.setupType,
            customStages: this.customizeStages,
            customUserEnabling: this.customUserEnabling,
            includeSMSDrips: this.formModel.includeSMSDrips,
            legacyPricing: this.legacyPricing
          },
          payment: {
            note: 'Lender Setup Payment',
            billing,
            locationId,
            sourceId,
          },
          businessAddress: this.formModel.businessAddress
        })
        this.receiptUrl = receiptUrl
        this.loading = false
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
        this.loading = false
      }
    },
  },
};
</script>
<style scoped>
label {
  @apply mr-2;
}

.field {
  @apply mt-2;
}

input[type="radio"] {
  @apply mr-2;
}

.field-block-error {
  @apply inline-block border border-red-500 p-2 rounded;
}
</style>
