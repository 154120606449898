<template>
  <TextureBackground>
    <div class="min-h-screen flex flex-col">
      <header class="text-white">
        <div class="container mx-auto py-10">
          <div class="text-center">
            <Logo />
          </div>
        </div>
      </header>
      <main class="flex-grow px-8" :class="{'bg-gray-200 py-20': !transparentMain}">
        <div class="container mx-auto">
          <h1 v-if="title" class="text-4xl text-center font-semibold">{{ title }}</h1>
          <div v-if="error" class="text-center mt-10">
            <p class="text-xl">There was an isssue submitting your information.</p>
            <p class="mt-5">If the error persists or you need our assistance please contact <MailToLink subject="KTS Form Failure" body="I've encountered a form submission error." /></p>
          </div>
          <slot v-else-if="success" name="success"></slot>
          <div v-else-if="loading" class="text-center mt-10">
            <p class="text-xl mb-5">Hold tight! Your information is being submitted.</p>
            <font-awesome-icon icon="fa-solid fa-spinner" spin class="text-3xl text-site-purple"/>
          </div>
          <slot v-else></slot>
        </div>
      </main>
      <footer class="py-8 px-6 text-white">
        <p class="text-center font-medium">© {{$copyrightDate}} by Kee Technology Solutions LLC</p>
      </footer>
    </div>
  </TextureBackground>
</template>
<script>
import TextureBackground from '../common/TextureBackground.vue'
export default {
  props: ['title', 'transparentMain', 'loading', 'error', 'success'],
  components: {
    TextureBackground
  },
}
</script>