export const isoToMonthDayYear = (isoDate) => {
  var dateObj = new Date(isoDate);

  var options = { year: 'numeric', month: 'long', day: 'numeric' };

  return dateObj.toLocaleDateString('en-US', options);
}

export const videoHtml = (videoId, videoType = 'YouTube') => {
  const iframe = videoType === 'YouTube'
    ? `<iframe src="https://www.youtube.com/embed/${videoId}" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
    : `<iframe src="https://www.loom.com/embed/${videoId}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>`

  return `<div style="position: relative; width: 100%; height: 0; padding-bottom: 56.25%;">${iframe}</div>`
}

export const objectToQueryString = (obj) =>
  Object
    .keys(obj)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&')

export const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export const snakeObjectKeys = (obj) =>
  Object
    .keys(obj)
    .reduce(
      (acc, key) => {
        acc[camelToSnakeCase(key)] = obj[key]
        return acc;
      },
      {}
    )

export const calendlyUTMQuerytring = (utm) => objectToQueryString(snakeObjectKeys(utm))

export const buildCalendarUrl = ({ calendar, utm }) => `https://calendly.com/${calendar}?${calendlyUTMQuerytring(utm)}`