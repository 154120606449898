<template>
  <section class="py-20 px-6 text-white">
    <div class="container mx-auto">
      <div class="flex flex-col-reverse lg:flex-row">
        <div class="lg:pr-10">
          <h3 class="hidden lg:block text-4xl font-bold">Have any of these pain points? 👉</h3>
          <p class="mt-10 text-lg">Whether you have a virtual assistant now or plan to hire one in the future, if you are ready to delegate more off of your plate to a virtual assistant and grow your business, then you've come to the right place. We're here to help ensure your virtual assistant becomes an expert at Follow Up Boss so that you can stay focused on what you do best: creating relationships and closing deals.</p>
        </div>
        <div class="bg-white text-black p-5 rounded-xl flex-shrink-0 font-medium">
          <p v-for="item, idx in painPoints" :key="`pain_point_${idx}`" class="py-3" :class="{ 'border-t': idx > 0 }">{{ item }}</p>
        </div>
        <h3 class="lg:hidden text-center text-4xl font-bold mb-10">Do any of these sound familiar?</h3>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  computed: {
    painPoints() {
      return [
        "I have a CRM setup, but leads are still falling through the cracks",
        "I am spending too much time on manual tasks",
        "I don't have the time to train a virtual assistant",
        "I struggle with finding what I actually need help with in my CRM",
        "I have too many tasks",
        "I feel like I've been going down rabbit holes",
        "I'm worried a virtual assistant is going to break something",
        "I'm struggling with SOPs for Follow Up Boss within my company"
      ]
    }
  }
}
</script>