<template>
  <div class="fixed inset-0 bg-gray-900 bg-opacity-60 md:p-20 overflow-y-auto z-1000">
    <div class="bg-white p-10 mx-auto relative" :class="maxWidth">
      <div
        v-if="$listeners && $listeners.close"
        @click="$emit('close')"
        class="close-button"
      >
        <font-awesome-icon icon="fa-solid fa-times" class="text-xl text-white" />
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: 'lg',
      validator(value) {
        return ['sm', 'md', 'lg'].includes(value);
      }
    }
  },
  computed: {
    maxWidth() {
      switch (this.size) {
        case 'sm': return 'max-w-2xl'
        case 'md': return 'max-w-4xl'
        case 'lg':
        default: return 'max-w-6xl'
      }
    }
  }
}
</script>
<style scoped>
.close-button {
  @apply absolute right-3 top-3 cursor-pointer hover:opacity-75 bg-gray-800 h-7 w-7 rounded-full flex items-center justify-center
}
</style>