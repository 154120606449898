<template>
  <Modal @close="$emit('close')">
    <div class="mt-5">
      <h1 class="text-lg">We will use the following stages to trigger our automations. (We will provide recommendations for merging any comparable stages you already have in your FUB)</h1>
      <ul class="mt-5 list-disc">
        <li v-for="stage, idx in defaultStages" :key="idx">{{ stage }}</li>
      </ul>
      <h1 class="text-lg mt-10">When Zilliow 2 Way Sync is enabled, we will use the following stages to trigger our automations (if you plan to use Zillow 2 way sync, it must be enabled before we provide our setup):</h1>
      <ul class="mt-5 list-disc">
        <li v-for="stage, idx in zillowStages" :key="idx">{{ stage }}</li>
      </ul>
    </div>
  </Modal>
</template>
<script>
export default {
  computed: {
    defaultStages() {
      return [
        'A - Hot 1-3 Months: Buying or selling in less than 3 months',
        'B - Warm 3-6 Months: Buying or selling in 3-6 months',
        'C - Cold 6+ Months: Buying or selling in 6+ months',
        'Renter - future buyer: Can’t buy now, but may be able to in the future'
      ]
    },
    zillowStages() {
      return [
        'Lead (syncs with Zillow status “New”) - Lead not reached, no manual attempt made yet',
        'Attempted Contact - A lead you’re working to contact through phone calls, text and email.',
        'Spoke with Customer - A customer you’re speaking with but haven’t had a chance to meet.',
        'Nurture - Apply this status for contacts who aren’t ready to tour homes yet or have slowed their search.'
      ]
    }
  }
}
</script>