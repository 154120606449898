<template>
  <StandardPageLayout>
    <div v-if="authenticated">
      <router-view></router-view>
    </div>
    <div v-else class="text-center">
      <input type="password" v-model="password" v-on:keyup.enter="proceed" class="form-input" placeholder="password">
      <br>
      <button class="button mt-5" :disabled="!password" @click="proceed">Next</button>
      <p v-if="error" class="mt-2 text-red-500">{{ error }}</p>
    </div>
  </StandardPageLayout>
</template>
<script>

export default {
  data() {
    return {
      authenticated: false,
      error: '',
    }
  },
  created() {
    if (this.$route.path === '/admin') {
      this.$router.replace('/')
    }
  },
  computed: {
    password: {
      get () {
        return this.$store.state.adminPassword
      },
      set (value) {
        this.$store.commit('updateAdminPassword', value)
      }
    }
  },
  methods: {
    async proceed() {
      this.error = ''
      try {
        await this.$service.decrypt({ value: 'login' })
        this.authenticated = true
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
      }
    }
  }
}
</script>