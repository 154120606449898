<template>
  <section class="py-20 px-6 text-white" id="service">
    <div class="container mx-auto">
      <h3 class="text-center text-4xl font-bold">What's Included</h3>
      <div class="mt-20 bg-white text-black p-10 rounded-xl leading-loose">
        <ul class="list-disc">
          <li>Your virtual assistant will have lifetime access to a living, breathing online course totalling over six hours of in-depth training</li>
          <li>Your virtual assistant will have 90 days access to our calendar of live, interactive training with a success manager totalling over 50 hours of calls. These calls are a safespace for your virtual assistant to attend and ask any questions they may have</li>
          <li>Your virtual assistant will have 90 days access to all previous call recordings (including ones they did not have a chance to attend)</li>
          <li>Your virtual assistant will have access to email their customer success manager</li>
          <li>Your virtual assistant will be given standard operating procedures they can swipe & steal for daily, weekly, monthly, and quarterly tasks</li>
          <li>Your virtual assistant will be given step by step instructions for recommended tasks they take on as a database manager</li>
        </ul>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      showMoreActionPlans: false,
      showMoreDealsAutomations: false
    }
  }
}
</script>
<style scoped>
hr {
  @apply border-gray-300 my-5;
}
</style>
