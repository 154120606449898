<template>
  <div style="max-width: 1024px;" class="mx-auto p-5">
    <div v-for="plan in plans" :key="plan.id" class="p-5 mt-5 rounded-lg border shadow-md text-sm">
      <div class="lg:flex">
        <div class="lg:flex flex-grow lg:border-r pr-4">
          <div class="flex flex-col items-center flex-shrink-0 whitespace-nowrap">
            <img :src="plan.img" class="w-40">
            <h2 class="font-semibold mt-3">{{ plan.name }}</h2>
            <div @click="toggleDetails(plan.id)" class="hidden select-none text-xs mt-3 text-blue-500 font-medium lg:flex items-center px-2 cursor-pointer rounded-full">
              <span>{{ plan.showMore ? 'Hide' : 'View' }} Details</span>
              <font-awesome-icon :icon="`fa-solid fa-chevron-${plan.showMore ? 'up' : 'down'}`" class="ml-1" />
            </div>
          </div>
          <div class="sm:flex flex-grow flex-wrap lg:flex-nowrap justify-around lg:justify-between lg:pl-8 my-10 py-10 lg:py-0 lg:my-0 border-t border-b lg:border-0 lg:space-x-0">
            <div class="flex flex-col items-center">
              <h3 class="whitespace-nowrap">Advertised Price</h3>
              <h6 class="text-gray-500">at 1000 kWh</h6>
              <div class="sm:h-20 text-xl font-medium flex justify-center items-center mt-2 sm:mt-0 text-gray-700">15.2¢</div>
            </div>
            <div class="flex flex-col items-center mt-10 sm:mt-0">
              <h3 class="whitespace-nowrap">Ture Price</h3>
              <h6 class="text-gray-500">per kWh</h6>
              <div class="sm:h-20 text-blue-700 text-2xl font-semibold flex justify-center items-center mt-2 sm:mt-0">17.5¢</div>
            </div>
            <div class="text-center mt-10 sm:mt-0">
              <h3 class="whitespace-nowrap">Total Fixed Fees</h3>
              <h6 class="text-white select-none hidden sm:block">space</h6>
              <div class="sm:h-20 text-xl font-medium flex justify-center items-center mt-2 sm:mt-0 text-gray-700">$9.18</div>
            </div>
            <div class="text-center whitespace-nowrap mt-10 sm:mt-0">
              <h3>Monthly Estimate</h3>
              <h6 class="text-gray-500">at 1000 kWh</h6>
              <div class="sm:h-20 text-blue-700 text-2xl font-semibold flex justify-center items-center mt-2 sm:mt-0">$184.18</div>
            </div>
            <div class="text-center whitespace-nowrap mt-10 sm:mt-0">
              <h3>Term</h3>
              <h6 class="text-gray-500">months</h6>
              <div class="sm:h-20 text-xl font-medium flex justify-center items-center mt-2 sm:mt-0 text-gray-700">12</div>
            </div>
          </div>
        </div>
        <div class="lg:flex lg:flex-col justify-center lg:pl-4 lg:mt-0">
          <div class="font-semibold bg-blue-500 px-5 py-2 rounded w-full text-white hover:bg-blue-400 text-center cursor-pointer whitespace-nowrap max-w-xs mx-auto text-base">Let's Go!</div>
          <p class="text-center mt-3  text-gray-500 whitespace-nowrap">(888) 555-0000</p>
        </div>
      </div>
      <div class="flex justify-center lg:hidden">
        <div @click="toggleDetails(plan.id)" class="select-none mt-3 text-blue-500 font-medium flex items-center px-2 cursor-pointer rounded-full">
          <span>{{ plan.showMore ? 'Hide' : 'View' }} Details</span>
          <font-awesome-icon :icon="`fa-solid fa-chevron-${plan.showMore ? 'up' : 'down'}`" class="ml-1" />
        </div>
      </div>
      <div v-if="plan.showMore" class="mt-10">
        <div class="lg:flex justify-center whitespace-nowrap">
          <div class="lg:mx-5 my-5 lg:my-0 text-center">% Renewable: 100%</div>
          <div class="lg:mx-5 my-5 lg:my-0 text-center">Rate Type: Fixed</div>
          <div class="lg:mx-5 my-5 lg:my-0 text-center">Cancelation Fee: $250</div>
        </div>
        <div class="mt-5 text-center">
          <p>Power your home with renewable energy straight from Texas wind and solar farms. 100% Renewable Energy.</p>
          <p>Rangler Rewards Included. Giddy Up Guaranteed!</p>
        </div>
        <div class="flex flex-col lg:flex-row lg:space-x-4 mt-5 text-gray-500 items-center lg:items-stretch space-y-4 lg:space-y-0">
          <div class="border border-blue-800 rounded-lg w-full sm:w-96 lg:w-1/3 p-5">
            <div class="flex justify-between items-center">
              <p>True Price per kWh</p>
              <span class="text-2xl text-blue-700 font-semibold">17.5¢</span>
            </div>
            <div class="h-px bg-gray-500 my-3"></div>
            <p class="font-semibold">True Price Breakdown</p>
            <div class="pl-3">
              <div class="flex justify-between items-center mt-1">
                <p><span class="underline">Provider</span> Energy Charge</p>
                <p class="text-lg text-blue-700 font-semibold">12.5¢</p>
              </div>
              <div class="flex justify-between items-center mt-1">
                <p><span class="underline">TDU</span>  Usage Charge*</p>
                <p class="text-lg text-blue-700 font-semibold">5.03¢</p>
              </div>
            </div>
          </div>
          <div class="border border-blue-800 rounded-lg w-full sm:w-96 lg:w-1/3 p-5">
            <div class="flex justify-between items-center">
              <p>Total Fixed Fees</p>
              <span class="text-xl text-blue-700 font-semibold">17.5¢</span>
            </div>
            <div class="h-px bg-gray-500 my-3"></div>
            <p class="font-semibold">Fixed Fees Breakdown</p>
            <div class="pl-3">
              <div class="flex justify-between items-center mt-1">
                <p><span class="underline">Provider</span> Base Charge</p>
                <p class="text-lg text-blue-700 font-semibold">$4.95</p>
              </div>
              <div class="flex justify-between items-center mt-1">
                <p><span class="underline">TDU</span>  Delivery Charge*</p>
                <p class="text-lg text-blue-700 font-semibold">$4.23</p>
              </div>
            </div>
          </div>
          <div class="border border-blue-800 rounded-lg w-full sm:w-96 lg:w-1/3 p-5">
            <h2 class="font-semibold text-base text-center">Advertised Rates</h2>
            <table class="text-center w-full border border-collapse mt-3 font-semibold">
              <tr class="bg-blue-100">
                <td class="border">500 kWh</td>
                <td class="border">1000 kWh</td>
                <td class="border">2000 kWh</td>
              </tr>
              <tr>
                <td class="border">16.1¢</td>
                <td class="border">15.2¢</td>
                <td class="border">14.8¢</td>
              </tr>
            </table>
            <div class="flex justify-between mt-3">
              <div>Bill Credit</div>
              <div>$0.00</div>
            </div>
            <div class="flex justify-between mt-1">
              <div>Minimum</div>
              <div>$0.00</div>
            </div>
            <div class="text-center mt-3">
              <a class="text-blue-500 underline cursor-pointer">Electricity Facts Label</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const testPlan = {
  img: 'https://images.ctfassets.net/uza84g7ie660/1RCBQOpxAzVQS26DsYslv5/7795e5ba749eedbd9fc0ee0b04b6cc09/True_Power_Logo.png',
  name: 'Clean & Green 12',
  showMore: false
}
export default {
  data() {
    return {
      plans: [
        {
          id: 1,
          ...testPlan,
        },
        {
          id: 2,
          ...testPlan,
        },
        {
          id: 3,
          ...testPlan
        }
      ]
    }
  },
  methods: {
    toggleDetails(id) {
      const planIdx = this.plans.findIndex(plan => plan.id === id)
      this.plans[planIdx].showMore = !this.plans[planIdx].showMore
    }
  }
}
</script>