import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import VueScrollTo from 'vue-scrollto'
import Toast from './plugins/toast'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import App from './components/App.vue'
import router from './router'
import './assets/styles/index.css'
import store from './store'
import Modal from '@/components/common/Modal'
import Logo from './components/common/Logo'
import Video from './components/common/Video'
import './icons'
import Contentful from './plugins/contentful'
import ContentfulRTE from './components/common/ContentfulRTE'
import StandardPageLayout from './components/layouts/StandardPageLayout'
import MailToLink from './components/common/MailToLink'
import * as service from './service'
import Spinner from './components/common/Spinner.vue'

export const isProduction = process.env.NODE_ENV === "production"

/*
---------------------
Vue Instance Setup
---------------------
*/
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('Modal', Modal)
Vue.component('Logo', Logo)
Vue.component('Video', Video)
Vue.component('ContentfulRTE', ContentfulRTE)
Vue.component('StandardPageLayout', StandardPageLayout)
Vue.component('MailToLink', MailToLink)
Vue.component('Spinner', Spinner)

Vue.config.productionTip = false

Vue.use(VueCompositionAPI) // using api for vuelidate for now. consider vue3 upgrade
Vue.use(VueScrollTo)
Vue.use(Toast)
Vue.use(Contentful)

Vue.prototype.$isProduction = isProduction
Vue.prototype.$copyrightDate = new Date().getFullYear()
Vue.prototype.$service = service

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
