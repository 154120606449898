<template>
  <div v-if="show">
    <div class="fixed z-1000 top-0 left-0 right-0">
      <p ref="banner" class="text-center bg-yellow-200 py-2 px-12 font-semibold text-yellow-700 text-sm md:text-base flex items-center justify-center">
        <nobr class='mr-2 text-xl hidden lg:inline'>{{ emoji }}</nobr>
        <span>{{ text }}</span>
        <nobr class='ml-2 text-xl hidden lg:inline'>{{ emoji }}</nobr>
      </p>
      <div @click="closeBanner" class="absolute right-5 top-1/2 transform -translate-y-1/2 bg-yellow-700 cursor-pointer w-5 h-5 rounded-full flex justify-center items-center text-yellow-200">
        <font-awesome-icon icon="fa-solid fa-times" />
      </div>
    </div>
    <div class="hidden lg:block" :style="{height: bannerOffset}"></div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  watch: {
    show() {
      this.$nextTick(() => this.setBannerOffset(this.$refs.banner.clientHeight))
    }
  },
  computed: {
    ...mapGetters(['siteBanner']),
    bannerOffset() {
      return this.siteBanner.bannerOffset
    },
    show() {
      return this.siteBanner.show
    },
    text() {
      return this.siteBanner.text
    },
    emoji() {
      return this.siteBanner.emoji
    }
  },
  methods: {
    ...mapActions(['setBannerOffset', 'closeBanner'])
  }
}
</script>