<template>
  <StandardPageLayout>
    <div v-if="loading"></div>
    <div v-else-if="error">
      <p class="text-center">Something went wrong. Please reach out to <MailToLink subject="Affiliate payout page failed to load" body="I was unable to view my affiliate payout page." /></p>
    </div>
    <div v-else>
      <h2 class="text-center text-xl">Affiliate Payout for {{ affiliateName }}</h2>
      <div class="bg-white mt-10 rounded-lg shadow-md">
        <div v-if="payoutNotes.length < 1" class="p-10 text-center">
          <p class="font-medium text-lg">No payout records were found.</p>
          <p class="mt-3">If you believe there is an issue, please reach out to <MailToLink subject="No Affiliate Payout Records Found" body="I was unable to retrieve my affiliate payout records. I believe there is an issue with this." /></p>
        </div>
        <div v-else v-for="{ id, body, created } in payoutNotes" :key="id" class="p-5 border-b text-center">
          <p class="text-sm">Payout Note</p>
          <p class="text-sm bg-site-purple inline-block text-white rounded-full px-3 py-1 mt-1">Created: <span class="font-medium">{{ created }}</span></p>
          <p class="mt-3 whitespace-pre-wrap">{{ body }}</p>
        </div>
      </div>
    </div>
  </StandardPageLayout>
</template>
<script>
export default {
  props: ['keeId'],
  data: () => ({
    loading: true,
    error: false,
    affiliateName: '',
    payoutNotes: []
  }),
  async created() {
    try {
      const { affiliateName, payoutNotes } = await this.$service.getAffiliatePayoutNotes(this.keeId)
      if (!affiliateName) throw new Error('Failed to load affiliate')
      this.affiliateName = affiliateName
      this.payoutNotes = payoutNotes
      this.loading = false
    } catch {
      this.error = true
    }
  }
}
</script>