<template>
  <LandingPageLayout @handleCTA="openSignUp" :navItems="navItems">
    <Hero ref="hero" :setupType="setupType" :calendarUrl="calendarUrl" />
    <Benefits />
    <PainPoints />
    <WhatsIncluded />
    <FAQ :faqList="lenderFaq" />
    <Pricing @openSignUp="openSignUp" :basePrice="basePrice" :payInFull="true" :addOns="addOns" />
    <Schedule @openSignUp="openSignUp" :calendarUrl="calendarUrl" />
  </LandingPageLayout>
</template>
<script>
import LandingPageLayout from '../../layouts/LandingPageLayout'
import Hero from '../../common/landing/Hero'
import Benefits from '../home/Benefits'
import PainPoints from '../home/PainPoints'
import WhatsIncluded from './WhatsIncluded'
import FAQ from '../../common/landing/FAQ'
import Schedule from '../../common/landing/Schedule'
import Pricing from '../../common/landing/Pricing.vue'
import navItems from './navItems'

import { mapGetters } from 'vuex';
import { SETUP_TYPE, CALENDLY_UTM, CALENDLY_CALENDARS } from '../../../constants';
import { buildCalendarUrl } from '../../../utils'
import { getPricing } from '@/pricing'

export default {
  components: {
    LandingPageLayout,
    Hero,
    Benefits,
    PainPoints,
    WhatsIncluded,
    FAQ,
    Pricing,
    Schedule,
  },
  computed: {
    ...mapGetters(['lenderFaq']),
    setupType() {
      return SETUP_TYPE.FUB_LENDER_REVAMP
    },
    navItems() {
      return navItems
    },
    pricing() {
      return getPricing(SETUP_TYPE.FUB_LENDER_REVAMP)
    },
    basePrice() {
      return this.pricing.BASE.toLocaleString('en')
    },
    addOns() {
      return [
        {
          title: 'SMS Drips',
          subtext: '* Must have Leadngage, Texting Betty, or StreetText',
          price: this.pricing.SMS_DRIPS.toLocaleString('en'),
        }
      ]
    },
    calendarUrl() {
      return buildCalendarUrl({
        calendar: CALENDLY_CALENDARS.LENDER_DISCOVERY,
        utm: CALENDLY_UTM.LENDER_DISCOVERY
      })
    },
  },
  methods: {
    openSignUp() {
      this.$refs.hero.openSignUp()
    },
  }
}
</script>