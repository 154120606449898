<template>
  <div class="container mx-auto text-center mt-10">
    <div v-if="error">Error: {{ error }}</div>
    <div v-else-if="loading">Loading...</div>
    <div v-else-if="success">The cancellation of subscription <strong>{{subId}}</strong> has been updated.</div>
    <form v-else @submit.prevent="submit">
      <p class="text-xl mb-5">Subscription Cancellation</p>
      <input class="form-input" v-model="subId" type="text" placeholder="subscription id" />
      <br>
      <button class="button mt-5" type="submit">Submit</button>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      error: '',
      loading: false,
      success: false,
      subId: ""
    }
  },
  methods: {
    async submit() {
      if (!this.subId) return
      try {
        this.error = ''
        this.loading = true
        await this.$service.cancelSubscription(this.subId)
        this.loading = false
        this.success = true
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
        this.loading = false
      }
    }
  }
}
</script>