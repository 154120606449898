<template>
  <a :href="`mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`" class="link">{{ emailAddress }}</a>
</template>
<script>
export default {
  props: {
    emailAddress: {
      type: String,
      default: 'admin@keetechnology.com'
    },
    subject: {
      type: String,
      default: ''
    },
    body: {
      type: String,
      default: '' 
    }
  }
}
</script>