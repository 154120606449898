<template>
  <div>
    <p class="text-xl mt-3">If you haven't done so already, be sure to watch out our TC Assist Demo Video</p>
    <div class="max-w-2xl mx-auto mt-10 bg-kts-gold p-2">
      <Video :videoId="demoVideoId" />
    </div>
  </div>
</template>
<script>
export default {
  props: ['demoVideoId'],
}
</script>