<template>
  <StandardPageLayout :transparentMain="true">
    <VSL v-if="keeId" :keeId="keeId" />
    <DiscoverLanding v-else  @proceed="proceed"/>
  </StandardPageLayout>
</template>
<script>
import DiscoverLanding from './DiscoverLanding'
import VSL from './VSL'
export default {
  components: {
    DiscoverLanding,
    VSL
  },
  data() {
    return {
      keeId: ''
    }
  },
  methods: {
    proceed(keeId) {
      this.keeId = keeId
    }
  }
}
</script>