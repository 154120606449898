<template>
  <div
    id="app-inner"
    class="opacity-0 transition-opacity duration-500 relative"
    :class="{ 'opacity-100': appOpaque }"
  >
    <Toast />
    <Banner />
    <router-view></router-view>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import Banner from './common/Banner.vue'
export default {
  components: {
    Banner
  },
  data() {
    return {
      appOpaque: false,
    };
  },
  beforeCreate() {
    if (this.$isProduction) {
      // fub pixel
      let fubPixelScript = document.createElement("script");
      fubPixelScript.setAttribute("src", "fubPixel.js");
      document.head.appendChild(fubPixelScript);
      
      // facebook pixel
      let facebookPixelScript = document.createElement("script");
      facebookPixelScript.setAttribute("src", "facebookPixel.js");
      document.head.appendChild(facebookPixelScript);
    }
  },
  created() {
    this.getSiteContent()
  },
  mounted() {
    setTimeout(() => this.appOpaque = true, 100);
  },
  methods: {
    ...mapActions(['getSiteContent'])
  }
};
</script>
