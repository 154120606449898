<template>
  <div class="mt-10">
    <div class="text-center text-kts-gold">
      <h3 class="text-lg">FOR REAL ESTATE AGENTS, BROKERS, AND PROFESSIONALS STRUGGLING WITH LEAD MANAGEMENT</h3>
      <h1 class="text-4xl font-bold text-white mt-5">UNLOCK THE SECRET TO MAXIMIZING TIME FOR CLIENTS, LEADS, AND FOLLOW-UPS EACH WEEK</h1>
      <h2 class="mt-5 text-3xl font-bold">WITHOUT COMPROMISING YOUR PERSONAL TIME!</h2>
    </div>
    <div class="flex flex-col lg:flex-row items-center mt-10">
      <div class="hidden lg:block lg:w-1/3">
        <img :src="require('@/assets/images/computer.jpg')" />
      </div>
      <div class="lg:w-2/3 pl-10 text-white">
        <p class="text-lg">Our specialized real estate CRM systems will increase your efficiency, improve your response and engaged rates, start more conversations, close more deals, organize your lead management system, and give you more time to focus on what matters most - your clients.</p>
        <div class="mt-10 text-lg font-medium">
          <table>
            <tr>
              <td>✅</td>
              <td class="text-left pl-3">More conversations & closed deals while spending less time on manual work in your CRM</td>
            </tr>
          </table>
          <table class="mt-5">
            <tr>
              <td>✅</td>
              <td class="text-left pl-3">Unlock the secret to a 49% lead response rate without wasting time on writing emails that don't get results!</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <p class="text-center text-lg text-white mt-10">Looking to get your hands on <strong>effective real estate CRM operating procedures and automations?</strong></p>
    <div class="mt-5 flex justify-center">
      <div class="bg-kts-gold py-5 px-10 rounded-xl text-xl font-medium cursor-pointer text-center hover-bright" @click="showModal=true">Access CRM Operating Procedures & Automations</div>
    </div>
    <p class="text-white text-center mt-5 text-lg mb-5">Click ☝️ to gain access!</p>
    <Modal v-if="showModal" @close="showModal=false" size="sm">
      <h2 class="text-center mt-3 text-2xl font-bold">GET INSTANT ACCESS TO OUR<br>EXCLUSIVE CRM TRAINING</h2>
      <SimpleSignup @form-submit="handleSignup" :error="signupError"  />
    </Modal>
  </div>
</template>
<script>
import SimpleSignup from '../../common/form/complete/SimpleSignup.vue'
export default {
  components: {
    SimpleSignup
  },
  data() {
    return {
      showModal: false,
      signupError: "",
    }
  },
  methods: {
    async handleSignup(formData) {
      try {
       const { keeId } = await this.$service.postConversionly(formData)
       this.$emit('proceed', keeId)
      } catch (e)  {
        this.signupError = this.$service.getErrorMessage(e)
      }
    }
  }
}
</script>