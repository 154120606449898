<template>
  <section class="py-20 px-6 bg-site-gray text-white" id="benefits">
    <div class="container mx-auto">
      <div class="flex justify-center">
        <div class="bg-kts-gold text-black py-1 px-4 rounded-full font-medium">Benefits</div>
      </div>
      <p class="text-center text-4xl mt-5 font-bold">Our Follow Up Boss training will...</p>
      <div v-for="benefit, idx in benefits" :key="`benefit_${idx}`" class="p-10 flex items-center" :class="{ 'border-t border-kts-gold': idx > 0 }">
        <div class="flex-grow">
          <div v-if="benefit.length > 2" class="text-kts-gold text-5xl font-bold">{{ idx + 1 }}</div>
          <h3 class="mt-4 text-3xl font-semibold">{{ benefit.title }}</h3>
          <p class="mt-4 text-lg">{{ benefit.body }}</p>
        </div>
        <div class="w-44 ml-14 flex-shrink-0 hidden md:block">
          <img :src="require(`@/assets/images/${benefit.image}`)" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  computed: {
    benefits() {
      return [
        {
          title: "Provide expert level understanding of Follow Up Boss",
          body: "Our training will allow your virtual assistant to have an advanced level of understanding in FUB so you can focus on scaling your business.",
          image: "conversations.svg"
        },
        {
          title: "Ensure no lead gets lost in your system",
          body: "Our Standard Operating Procedures will have step by step instructions for your virtual assistant to ensure no lead gets missed again",
          image: "workflow.svg"
        }
      ]
    }
  }
}
</script>