<template>
  <section>
    <div class="flex flex-col-reverse xl:flex-row">
      <!-- course library -->
      <div class="xl:mr-10 xl:w-96 xl:mt-0 mt-10 flex-shrink-0 flex-grow-0">
        <div v-for="({ title, videos }, courseIdx) in videoLists" :key="`course_${courseIdx}`" :class="{ 'mb-10': courseIdx !== videoLists.length - 1 }">
          <h3 class="px-5 py-1 text-center bg-kts-gold text-white text-lg font-semibold">{{ title }}</h3>
          <div class="border border-kts-gold py-5">
            <div v-for="(video, videoIdx) in videos" :key="`video_${videoIdx}`" class="pl-8 py-1 cursor-pointer flex transition-all ease-in-out duration-500" @click="$emit('show', video)" :class="{ 'bg-gray-700 text-white font-bold py-2': selectedVideoId === video.videoIdentifier, 'hover:bg-gray-300': selectedVideoId !== video.videoIdentifier }"><div v-if="selectedVideoId === video.videoIdentifier" class="w-1 bg-white mr-2"></div>{{ video.title }}</div>
          </div>
        </div>
      </div>
      <!-- video container -->
      <div v-if="selectedVideoId && selectedVideoType" class="flex-grow">
        <Video :videoId="selectedVideoId" :videoType="selectedVideoType" />
      </div>
    </div>
  </section>
</template>
<script>
import getOr from 'lodash/fp/getOr'
export default {
  props: {
    videoLists: {
      type: Array,
      required: true
    },
    selectedVideo: {
      type: Object,
      required: true
    }
  },
  computed: {
    selectedVideoId() {
      return getOr('', 'videoIdentifier')(this.selectedVideo)
    },
    selectedVideoType() {
      return getOr('', 'type')(this.selectedVideo)
    }
  }
}
</script>