<template>
  <StandardPageLayout>
    <div v-if="error !== ''">
      <h3>Whoops. An error has occurred.</h3>
      <p class="text-red">{{ error }}</p>
    </div>
    <div v-else-if="showingApiKey" class="text-center">
      <input v-model="apiKey" class="form-input" type="text" placeholder="api key" />
      <br />
      <button class="button mt-5" @click="toFolderSelect">Next</button>
    </div>
    <div v-else-if="showingFolderSelect">
      <p class="text-center mb-10">Action Plan Folders</p>
      <div
        class="border-b border-gray-300 py-2 px-5 text-sm cursor-pointer hover:bg-gray-300 transition-all flex justify-between items-center max-w-2xl mx-auto"
        v-for="folder in folders"
        :key="`folder_${folder.id}`"
        @click="selectedFolder = folder"
      >
        <p><font-awesome-icon icon="fa-solid fa-folder" /><span class="ml-2">{{ folder.name }}</span></p>
      </div>
      <Modal v-if="selectedFolder" @close="selectedFolder = null" class="text-center">
        <p class="text-lg font-semibold"><font-awesome-icon icon="fa-solid fa-folder" /><span class="ml-2">{{ selectedFolder.name }}</span></p>
        <select v-model="selectedUser" style="appearance: auto" class="border border-gray-400 mt-5 p-1">
          <option value="" disabled>Select User</option>
          <option v-for="user in users" :key="user.id" :value="user.id">
            {{ user.name }}
          </option>
        </select>
        <div v-if="selectedUser" class="mt-5">
          <p>Assign tasks within <strong>{{ selectedFolder.name }}</strong> to <strong>{{ (users.find(({ id }) => id === selectedUser)).name }}?</strong></p>
          <p v-if="assigningTasks" class="mt-5">Assigning tasks... <Spinner /></p>
          <button v-else class="button mt-5" @click="assignTasks">Assign Tasks</button>
        </div>
      </Modal>
    </div>
  </StandardPageLayout>
</template>
<script>
import fubApiMixin from '../../../mixins/fubApiMixin'
const STAGES = {
  API_KEY: "API_KEY",
  FOLDER_SELECT: "FOLDER_SELECT"
}

export default {
  mixins: [fubApiMixin],
  data() {
    return {
      error: "",
      stage: STAGES.API_KEY,
      folders: [],
      users: [],
      selectedUser: '',
      selectedFolder: null,
      assigningTasks: false,
    }
  },
  computed: {
    showingApiKey() {
      return this.stage === STAGES.API_KEY
    },
    showingFolderSelect() {
      return this.stage === STAGES.FOLDER_SELECT
    },
  },
  methods: {
    async toFolderSelect() {
      this.setupApi()
      this.stage = STAGES.FOLDER_SELECT
      
      try {
        this.folders = (await this.fubApi.getCategories({ limit: 100, type: 'actionPlan' })).categories
      } catch {
        this.error = 'Could not retrieve a list of action plan folders'
        return
      }

      try {
        this.users = (await this.fubApi.getUsers({ limit: 100 })).users
      } catch {
        this.error = 'Could not retrieve a list of users'
        return
      }
    },
    async assignTasks() {
      const getPlansInFolder = async (nextCursor = null, acc = []) => {
        const { _metadata: { next }, actionPlans } = await this.fubApi.getActionPlans({ status: 'Active', limit: 100, next: nextCursor })
        const plansInFolder = acc.concat(actionPlans.filter(plan => plan.categories.length === 1 && plan.categories[0].id === this.selectedFolder.id))
        return next ? getPlansInFolder(next, plansInFolder) : plansInFolder
      }

      try {
        this.assigningTasks = true

        const plans = await getPlansInFolder()

        for (const plan of plans) {
          const planToUpdae = await this.fubApi.getActionPlan(plan.id)
          const updatedSteps = planToUpdae.steps.map((step) => {
            if (step.action === 'createTask') {
              return {
                ...step,
                assignedUserId: this.selectedUser
              }
            } else {
              return step
            }
          })

          await this.fubApi.updateActionPlan(plan.id, { steps: updatedSteps })
        }
        
        
        alert('Complete!')

        this.selectedUser = ''
        this.selectedFolder = null
        this.assigningTasks = false
      } catch (e) {
        this.error = 'Could not assign'
        return
      }
    }
  }
}
</script>