<template>
  <div class="mt-2">
    <SelectWrapper>
      <select :value="value" @input="$emit('input', $event.target.value)" :class="{ 'border-red-500': errorMessage, 'text-gray-400': value === '' }" class="form-input">
        <option disabled value="">{{ 'Deposit Holding Select' }}</option>
        <option v-for="item in items" :key="item" :value="item">{{ item }}</option>
      </select>
    </SelectWrapper>
    <p v-if="errorMessage" class="text-xs text-red-500">{{ errorMessage }}</p>
  </div>
</template>
<script>
import SelectWrapper from '@/components/common/form/SelectWrapper'
export default {
  components: { SelectWrapper },
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    items() {
      return ['Escrow', 'Title', 'Broker Trust Account', 'Other (specify)']
    }
  }
}
</script>