<template>
  <div class="calendly-inline-widget" :style="{ 'min-width': '320px', 'height': '750px' }" data-auto-load="false"></div>
</template>
<script>
export default {
  props: ['url'],
  methods: {
    initCalendly() {
      window.Calendly.initInlineWidget({
        url: this.url,
        parentElement: document.querySelector(".calendly-inline-widget"),
      });
    },
  },
  mounted() {
    if (document.getElementById("calendly-script")) {
      this.initCalendly()
    } else {
      let calendlyScript = document.createElement("script")
      calendlyScript.onload = () => this.initCalendly()
      calendlyScript.async = true
      calendlyScript.src = "https://assets.calendly.com/assets/external/widget.js"
      document.head.appendChild(calendlyScript)
    }
  },
}
</script>
