<template>
  <section class="py-20 px-6 text-white" id="pricing">
    <div class="container mx-auto">
      <h3 class="text-center text-4xl font-bold">Curious about pricing?</h3>
      <div class="mt-20 bg-gray-100 text-gray-700 p-10 lg:p-20 rounded-xl border-8 border-kts-gold">
        <p class="text-center text-3xl font-semibold">TC Assist</p>
        <p class="text-center text-lg">Transaction Coordinator Follow Up Boss Build</p>
        <div class="flex justify-center mt-10"><p class="bg-site-purple text-white rounded-full px-3 py-1 text-sm font-medium">Solo Agent</p></div>
        <p class="text-center text-4xl mt-2 font-semibold">${{pricing.BASE.toLocaleString('en')}} <span class="text-xl">USD</span></p>
        <div class="flex justify-center mt-10"><p class="bg-site-purple text-white rounded-full px-3 py-1 text-sm font-medium">Team</p></div>
        <p class="text-center text-4xl mt-2 font-semibold">${{(pricing.BASE + pricing.FIRST_TC_SEAT).toLocaleString('en')}} <span class="text-xl">USD</span></p>
        <p class="text-gray-500 text-center mt-2 text-sm">* Team price includes one transaction coordinator seat.<br>Additional seats can be included in the setup for $200 per seat.</p>
        <p class="font-semibold mt-10 text-center text-lg">Pay 50% deposit at sign up.</p>
        <p class="text-center">Pay 50% balance two weeks after the setup is complete.</p>
        <hr />
        <p class="text-center text-2xl">Just some basic information is needed to get the ball rolling!</p>
        <div class="text-center mt-10">
          <a class="bg-kts-gold text-black uppercase font-semibold py-4 px-10 text-lg inline-block cursor-pointer tracking-wider hover-bright" @click="$emit('openSignUp')">Get Started</a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ['pricing']
}
</script>
<style scoped>
hr {
  @apply border-gray-300 my-14;
}
</style>