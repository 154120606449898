<template>
  <section class="py-20 px-6 bg-site-gray text-white" id="benefits">
    <div class="container mx-auto">
      <div class="flex justify-center">
        <div class="bg-kts-gold text-black py-1 px-4 rounded-full font-medium">Benefits</div>
      </div>
      <p class="text-center text-4xl mt-5 font-bold">Our turn-key Transaction Coordinator build will...</p>
      <div v-for="benefit, idx in benefits" :key="`benefit_${idx}`" class="p-10 flex items-center" :class="{ 'border-t border-kts-gold': idx > 0 }">
        <div class="flex-grow">
          <div class="text-kts-gold text-5xl font-bold">{{ idx + 1 }}</div>
          <h3 class="mt-4 text-3xl font-semibold">{{ benefit.title }}</h3>
          <p class="mt-4 text-lg">{{ benefit.body }}</p>
        </div>
        <div class="w-44 ml-14 flex-shrink-0 hidden md:block">
          <img :src="require(`@/assets/images/${benefit.image}`)" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  computed: {
    benefits() {
      return [
        {
          title: "Save you time and cost managing transaction checklists in other software",
          body: "Our action plans include tasks and email content for both the Transaction Coordinator and Agent to lead Buyers and Sellers through to a smooth closing.",
          image: "conversations.svg"
        },
        {
          title: "Provide you an efficient and collaborative workflow",
          body: "Create the ultimate productivity and Agent/TC collaboration through a simplified structure for Follow Up Boss.",
          image: "workflow.svg"
        },
        {
          title: "Ensure no transaction tasks get lost in the shuffle",
          body: "Our automations allow you to quickly ensure clients get the correct task lists applied to their type of transaction and no details go un-noticed.",
          image: "search.svg"
        }
      ]
    }
  }
}
</script>