import Vue from 'vue';
import Vuex from 'vuex';
import getOr from 'lodash/fp/getOr'

import contentfulClient from './contentfulClient'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    siteContent: {
      loaded: false,
      loading: false,
      content: {
        banner: {
          show: false,
          text: '',
          emoji: ''
        },
        agentRevampFaq: [],
        lenderFaq: [],
        transactionFaq: [],
        bossAssistFaq: [],
        sierraFaq: [],
        videoTestimonials: [],
      },
      error: false,
    },
    bannerOffset: '0px',
    bannerDisplay: false,
    fixedNav: false,
    adminPassword: '',
    affiliate: ''
  },
  getters: {
    bannerOffset: (state) => state.bannerOffset,
    siteBanner: (state) => ({
      bannerOffset: state.bannerOffset,
      ...state.siteContent.content.banner,
      show: state.siteContent.content.banner.show && state.bannerDisplay
    }),
    agentRevampFaq: (state) => state.siteContent.content.agentRevampFaq,
    lenderFaq: (state) => state.siteContent.content.lenderFaq,
    transactionFaq: (state) => state.siteContent.content.transactionFaq,
    bossAssistFaq: (state) => state.siteContent.content.bossAssistFaq,
    sierraFaq: (state) => state.siteContent.content.sierraFaq,
    videoTestimonials: (state) => state.siteContent.content.videoTestimonials,
    adminToken: (state) => btoa(`admin:${state.adminPassword}`),
    affiliate: (state) => state.affiliate
  },
  mutations: {
    // get site content
    getSiteContentStart(state) {
      state.siteContent.loading = true
    },
    getSiteContentEnd(state, content) {
      state.siteContent.loaded = true
      state.siteContent.loading = false
      state.siteContent.content = content
    },
    getSiteContentFail(state) {
      state.siteContent.loading = false
      state.siteContent.error = true
    },
    // banner
    setBannerOffset(state, offset) {
      state.bannerOffset = `${offset}px`
    },
    setBannerDisplay(state, display) {
      state.bannerDisplay = display
    },
    closeBanner(state) {
      state.siteContent.content.banner.show = false
      state.bannerOffset = '0px'
    },
    updateAdminPassword(state, value) {
      state.adminPassword = value
    },
    setAffiliate(state, value) {
      state.affiliate = value
    }
  },
  actions: {
    async getSiteContent({ commit }) {
      commit('getSiteContentStart')
      try {
        const siteEntry = await contentfulClient.getEntry('68T629CfuAQxA8BIcK7pmy', { include: 10 })
        commit('getSiteContentEnd', buildSiteContentFromEntry(siteEntry))
      } catch (error) {
        commit('getSiteContentFail')
      }
    },
    setBannerOffset({ commit }, offset) {
      commit('setBannerOffset', offset)
    },
    setBannerDisplay({ commit }, display) {
      commit('setBannerDisplay', display)
    },
    closeBanner({ commit }) {
      commit('closeBanner')
    },
    setAffiliate({ commit }, value) {
      commit('setAffiliate', value)
    }
  }
})

const getEntryFields = (entry) => getOr({}, 'fields')(entry)

const getFaqFromEntries = (faqEntires) => faqEntires.map((faqEntry) => getEntryFields(faqEntry)).map(({ question, answer }) => ({ question, answer: documentToHtmlString(answer) }))

const buildSiteContentFromEntry = (siteEntry) => {
  const {
    bannerMessage: bannerMessageEntry,
    showBanner,
    faqList: faqListEntries,
    lenderFaq: lenderFaqEntries,
    bossAssistFaq: bossAssistFaqEntires,
    transactionFaq: transactionFaqEntries,
    videoTestimonials: videoTestimonialsEntry,
    sierraFaq: sierraFaqEntries,
  } = getEntryFields(siteEntry)
  const videoTestimonials = videoTestimonialsEntry.fields.list.map((video) => getEntryFields(video))
  const agentRevampFaq = getFaqFromEntries(faqListEntries)
  const lenderFaq = getFaqFromEntries(lenderFaqEntries)
  const transactionFaq = getFaqFromEntries(transactionFaqEntries)
  const bossAssistFaq = getFaqFromEntries(bossAssistFaqEntires)
  const sierraFaq = getFaqFromEntries(sierraFaqEntries)
  const { text: bannerText, emoji: bannerEmoji = '' } = getEntryFields(bannerMessageEntry)
  return {
    banner: {
      show: showBanner,
      text: bannerText,
      emoji: bannerEmoji
    },
    agentRevampFaq,
    lenderFaq,
    transactionFaq,
    videoTestimonials,
    bossAssistFaq,
    sierraFaq
  }
}