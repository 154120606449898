<template>
  <StandardPageLayout title="KTS Affiliates">
    <div class="max-w-xl mx-auto mt-10">
      <div class="flex justify-center">
        <input type="text" placeholder="search" class="py-2 px-5 rounded-full w-56" v-model="searchTerm" />
      </div>
      <div class="mt-10">
        <div v-for="{name, slug} in filteredAffiliates" :key="slug">
          <div class="p-5 border-b border-kts-gold hover:bg-gray-300 transition ease-linear">
            <div>
              <div @click="copyToClipboard(name)" class="cursor-pointer text-lg font-semibold hover:text-kts-gold inline-block">{{ name }}</div>
            </div>
            <div>
              <div @click="copyToClipboard(baseUrl + slug)" class="mt-3 cursor-pointer hover:text-kts-gold inline-block">{{ baseUrl }}{{ slug }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </StandardPageLayout>
</template>
<script>
import _sortBy from 'lodash/sortBy'
export default {
  data: () => ({
    searchTerm: '',
    affiliates: []
  }),
  async created() {
    try {
      const affiliates = await this.$service.getAffiliateSlugs()
      this.affiliates = _sortBy(affiliates, 'name')
    } catch {
      console.error('Could not retrieve affiliates')
    }
  },
  computed: {
    filteredAffiliates() {
      return this.affiliates.filter(({ name }) =>
        name.toLowerCase().includes(this.searchTerm.toLowerCase())
      )
    },
    baseUrl: () => 'https://keetechnology.com/'
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text)
      this.$toast.show('Copied!', 2)
    }
  }
}
</script>