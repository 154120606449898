<template>
  <div
    :class="{ 'hidden': !showToast }"
    class="top-10 z-50 fixed left-1/2 bg-kts-gold text-white py-5 px-10 rounded-lg text-xl shadow-lg"
    style="transform: translateX(-50%);"
  >
    {{ toastText }}
  </div>
</template>
<script>
import Toast from '../../plugins/toast';
export default {
  data() {
    return {
      toastText: ""
    };
  },
  computed: {
    showToast() {
      return !!this.toastText
    },
  },
  methods: {
    show({ text, timeInSeconds = 5 }) {
      this.toastText = text;
      setTimeout(() => this.hide(), timeInSeconds * 1000);
    },
    hide() {
      this.toastText = "";
    },
  },
  beforeMount() {
    Toast.EventBus.$on('show', (params) => {
      this.show(params)
    })
  }
};
</script>
