<template>
  <div class="mt-2">
    <input
      type="text"
      :value="v.$model"
      :class="`${fieldClass} form-input`"
      @input="handleInput"
      :placeholder="placeholder"
      :autocomplete="autocomplete || 'on'"
    />
    <p v-if="errorMessage" class="text-xs text-red-500">{{ errorMessage }}</p>
  </div>
</template>
<script>
export default {
  props: ["v", "placeholder", "autocomplete", "size"],
  computed: {
    errorMessage() {
      return this.v.$error && this.v.$errors[0].$message
    },
    errorClass() {
      return this.errorMessage ? 'form-input-error' : ''
    },
    fieldClass() {
      return `${this.errorClass}` 
    }
  },
  methods: {
    handleInput(e) {
      this.v.$model = e.target.value
    }
  },
};
</script>