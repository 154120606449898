<template>
  <div class="text-center">
    <input type="text" v-model="cryptoStr" class="form-input"/>
    <div class="flex justify-center mt-2">
      <button class="button mr-1" :disabled="!cryptoStr" @click="encrypt">Encrypt</button>
      <button class="button ml-1" :disabled="!cryptoStr" @click="decrypt">Decrypt</button>
    </div>
    <div class="mt-5">
      <p v-if="error" class="text-red-500">{{ error }}</p>
      <p v-if="copied" class="font-medium text-lg">Copied!</p>
      <div v-else-if="cryptoVal" >
        <p class="font-medium">{{ cryptoVal }}</p>
        <button @click="copyCryptoVal" class="button-alt">
          <font-awesome-icon icon="fa-solid fa-clipboard" />
          <span class="ml-2">copy to clipboard</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cryptoStr: '',
      cryptoVal: '',
      error: '',
      copied: false,
    }
  },
  methods: {
    async encrypt() {
      this.error = ''
      this.copied = false
      this.cryptoVal = ''

      try {
        const { value } = await this.$service.encrypt({ value: this.cryptoStr })
        this.cryptoVal = value
        this.cryptoStr = ''
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
      }
    },
    async decrypt() {
      this.error = ''
      this.copied = false
      this.cryptoVal = ''

      try {
        const { value } = await this.$service.decrypt({ value: this.cryptoStr })

        if (value === '') {
          this.error = 'The value could not be decrypted.'
          return
        }

        this.cryptoVal = value
        this.cryptoStr = ''
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
      }
    },
    async copyCryptoVal() {
      await navigator.clipboard.writeText(this.cryptoVal);
      this.copied = true
      this.cryptoStr = ''
      this.cryptoVal = ''
      setTimeout(() => { this.copied = false }, 1500)
    },
  }
}
</script>