<template>
  <StandardPageLayout>
    <h1 class="text-center text-3xl font-medium">Group Training</h1>
    <div v-if="badEmail">
      <div class="text-center mt-10">
        <p>We made a mistake and broke the link in the email we sent you.</p>
        <p class="mt-5">To successfully load this page, please do the following:</p>
        <p class="font-semibold">Instead of clicking the link, please copy the link text verbatim and paste it into your browser's URL bar.</p>
        <p class="mt-5">Sorry for the inconvenience.</p>
      </div>
    </div>
    <div v-else-if="!googleCal">Calendar not avaialble</div>
    <div v-else-if="canAccess">
      <div class="flex justify-center mt-10">
        <button v-for="(title, idx) in tabTitles" :key="idx" @click="selectedTabIdx = idx" class="text-xl px-5 py-2 focus:outline-none transition-all ease-in-out duration-500 rounded-lg" :class="{ 'bg-kts-gold text-white font-semibold': selectedTabIdx === idx, 'hover:underline': selectedTabIdx !== idx }">{{ title }}</button>
      </div>
      <div v-if="selectedTabIdx === 0" class="bg-white p-10 rounded-xl shadow-md mt-10">
        <p class="text-center mt-2">Below are scheduled group training calls you may join</p>
        <p v-if="exclusiveAttendance" class="text-center text-sm mt-2 font-medium">*<strong>Exclusive attendance</strong><br>These calls are reserved for your dedicated group training ambassadors<br>(maximum of 2 ambassadors per team)</p>
        <div class="flex justify-center mt-10">
          <iframe :src="`https://calendar.google.com/calendar/embed?src=${googleCal}%40group.calendar.google.com&ctz=America%2FChicago`" style="border: 0" width="800" height="600" frameborder="0" scrolling="no"></iframe>
        </div>
      </div>
      <div v-else class="bg-white rounded-xl shadow-md mt-10">
        <div v-for="recording, idx in recordings" :key="recording.id" class="border-kts-gold p-5 text-center" :class="{ 'border-b': idx + 1 < recordings.length }">
          <span class="text-sm">{{ recording.formattedDate }}</span>
          <h2 class="font-semibold text-xl">{{ recording.title }}</h2>
          <div class="text-sm">Hosted by {{ recording.host }}</div>
          <a class="link" :href="recording.recordingUrl" target="_blank">View Recording</a>
        </div>
      </div>
    </div>
    <GroupTrainingExtension v-else-if="showExtension" :keeId="keeId" :setupType="setupType" />
    <div v-else class="text-center bg-white rounded-xl shadow-md p-10 mt-10">
      <h2 class="text-xl font-semibold">Forbidden 😔</h2>
      <p class="mt-2 text-lg font-medium">Looks like you don't have access.</p>
      <p class="mt-2">Looking to extend your group training access?<br>We offer a 30-day extension for $100.<br>Click the button below to checkout and receive a 30-day extension.</p>
      <button @click="showExtension = true" class="button mt-5">Extend Access</button>
      <p class="mt-5">If you believe there is an issue with your group training access please contact <MailToLink subject="Group Training Access Issue" body="I am having trouble accessing the group training page." /></p>
    </div>
  </StandardPageLayout>
</template>
<script>
import { getGroupTraining } from '../../../service'
import { SETUP_TYPE } from '../../../constants'
import GroupTrainingExtension from './GroupTrainingExtension.vue'
export default {
  props: ['keeId', 'setupType'],
  components: { GroupTrainingExtension },
  data() {
    return {
      badEmail: false,
      canAccess: true,
      showExtension: false,
      recordings: [],
      selectedTabIdx: 0
    }
  },
  async mounted() {
    // this is a temp fix to a bad emai template with a faield merge field %custom_kee_id%
    if (this.keeId === '%custom_kee_id%') {
      this.badEmail = true
      return
    }

    try {
      this.recordings = await getGroupTraining(this.keeId, this.setupType)
    } catch {
      this.canAccess = false
    }
  },
  computed: {
    tabTitles: () => ['Upcoming', 'Past Recordings'],
    googleCal() {
      switch (this.setupType) {
        case SETUP_TYPE.FUB_AGENT_REVAMP: return "c_b5d489fa0847a058f90efba4772aae9ce243577fd1340cca061ba64ffe4ea68f"
        case SETUP_TYPE.TC_ASSIST: return "c_a5781c38ce2fddf6358cd433644dd52877dd79864b919727cc8069e15c945d06"
        case SETUP_TYPE.BOSS_ASSIST: return "c_493e4f5a679304adee519903fa233aa737dce6fe9def3fab5d85d14c0acd85d8"
        case SETUP_TYPE.SIERRA: return "c_e3507556098b55e0b3e809a00cbd09fa41ca96a1332ddd93b60e79038e6db167"
        default: return ""
      }
    },
    exclusiveAttendance() {
      return this.setupType === SETUP_TYPE.FUB_AGENT_REVAMP
    },
  },
}
</script>

