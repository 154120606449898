<template>
  <div>
    <p class="text-xl mt-3">You're almost done! Follow these next 3 steps.</p>
    <section class="mt-20">
      <h3 class="text-2xl font-semibold">STEP 1: PLEASE WATCH THIS VIDEO BEFORE OUR APPOINTMENT!</h3>
      <div class="max-w-2xl mx-auto mt-10 bg-kts-gold p-2">
        <Video videoId="64kf5JSWBLQ" />
      </div>
    </section>
    <section class="mt-20">
      <h3 class="text-2xl font-semibold">STEP 2: CHECK OUT SOME REAL RESULTS FROM REAL CLIENTS!</h3>
      <h4>SEE HOW WE'VE HELPED HUNDREDS OF PEOPLE JUST LIKE YOU!</h4>
      <Tetimonials :simplified="true" class="mt-10" />
    </section>
    <section class="mt-20">
      <h3 class="text-2xl font-semibold">STEP 3: WATCH VIDEO DEMO</h3>
      <div class="max-w-2xl mx-auto mt-10 bg-kts-gold p-2">
        <Video :videoId="demoVideoId" />
      </div>
    </section>
  </div>
</template>
<script>
import Tetimonials from '../../home/Testimonials.vue'
export default {
  props: ['demoVideoId'],
  components: { Tetimonials },
}
</script>