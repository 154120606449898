<template>
  <div v-html="videoHtml" />
</template>
<script>
import { videoHtml } from '../../utils';
export default {
  props: {
    videoId: {
      type: String,
      required: true
    },
    videoType: {
      type: String,
      default: 'YouTube'
    }
  },
  computed: {
    videoHtml() {
      return videoHtml(this.videoId, this.videoType)
    }
  }
}
</script>