<template>
  <section :class="{'border-b border-gray-300 py-10': !embed, 'mt-5': embed }">
    <p v-if="chipText" class="bg-site-purple text-white inline-block py-1 px-3 rounded-full text-sm font-semibold">{{ chipText }}</p>
    <p class="font-semibold text-lg">{{ title }}<span v-if="helperText" class="text-base font-normal text-gray-700 ml-2">{{ helperText }}</span></p>
    <slot></slot>
  </section>
</template>
<script>
export default {
  props: ['title', 'helperText', 'chipText', 'embed']
}
</script>