<template>
  <div v-if="attemptedAffiliate" class="h-screen flex justify-center items-center">
    <div class="text-center">
      <h1 class="text-8xl">404</h1>
      <p class="text-2xl">Page not found.</p>
      <div class="mt-5">
        <router-link to="/" class="link text-lg">Take me back</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import Home from '../home'
import { SETUP_TYPE } from '../../../constants'

export default {
  data() {
    return {
      attemptedAffiliate: false
    }
  },
  async created() {
    try {
      const routePathArray = this.$route.path.slice(1).split('/')
      
      // Check if path starts with sierra or is at root level
      const isRootPath = routePathArray.length === 1
      const isSierraPath = routePathArray.length === 2 && routePathArray[0] === 'sierra'
      
      if (isRootPath || isSierraPath) {
        // Get the slug from the last segment of the path
        const slug = routePathArray[routePathArray.length - 1].toLowerCase()
        const affiliate = await this.$service.getAffiliateBySlug(slug)
        
        // Create route with the same path structure as the request
        const routePath = isRootPath ? `/${affiliate.slug}` : `/sierra/${affiliate.slug}`
        this.setAffiliate(affiliate.name)
        this.$router.addRoutes([
          { 
            path: routePath, 
            component: Home, 
            props: { setupType: isSierraPath ? SETUP_TYPE.SIERRA : SETUP_TYPE.FUB_AGENT_REVAMP } 
          }
        ])
        this.$router.replace(routePath)
      } else {
        this.attemptedAffiliate = true
      }
    } catch  {
      this.attemptedAffiliate = true
      console.error('Could not retrieve affiliates')
    }
  },
  methods: {
    ...mapActions(['setAffiliate'])
  },
}
</script>