import wretch from "wretch"
import store from './store'

export const getErrorMessage = ({ status, json = {} }) => {
  switch(status) {
    case 422:
      console.error('Joi Schema Validation Error: ', json.message)
      return 'Not a valid request payload.'
  }

  return json.message || 'Something went wrong.'
}

const rootUrl = process.env.NODE_ENV === "production" ? 'https://api.keetechnology.com' : 'http://localhost:3000'

export const api = (path) => wretch().resolve(resolver => resolver.json()).errorType("json").url(`${rootUrl}${path}`)
  
export const createSignup = (signUp) => api('/fub/sign-up').post(signUp)

export const manualSignUp = (signUp) => api('/fub/manual-signup').post(signUp)

export const manualSetupData = (payload) => api('/fub/manual-setup-data').post(payload)

export const createSetup = (setup) => api('/fub/setup-notes').post(setup)

export const payBalance = (balance) => api('/fub/balance').post(balance)

export const getPerson = (keeId) => api(`/fub/person/${keeId}`).get()

export const redeemGuide = (payload) => api('/guide/redeem').post(payload)

export const biWeeklyCallSignUp = (payload) => api('/fub/bi-weekly-call-sign-up').post(payload)

export const getGroupTraining = (keeId, setupType) => api(`/group-training/library/${keeId}`).query({ setupType }).get()

export const extendGroupTrainingAccess = (payload) => api('/group-training/extension').post(payload)

export const getAffiliateSlugs = () => api('/affiliate/slugs').get()

export const getAffiliateBySlug = (slug) => api(`/affiliate/slugs/${slug}`).get()

export const getAffiliatePayoutNotes = (keeId) => api(`/affiliate/payout-notes/${keeId}`).get()

export const postConversionly = (payload) => api('/fub/conversionly').post(payload)

export const postAuditInquiry = (payload) => api('/fub/audit-inquiry').post(payload)

export const postPromo = (payload) => api('/fub/promo').post(payload)

export const creds = (payload) => api('/fub/creds').post(payload)

export const bossAssistSignup = (payload) => api('/fub/boss-assist-signup').post(payload)

export const postCourseEnrollment = (payload) => api('/fub/course-enrollment').post(payload)

export const funnelTagging = (payload) => api('/fub/funnel-tagging').post(payload)

/* Admin Service */

export const adminApi = (path) => api(`/admin${path}`).auth(`Basic ${store.getters.adminToken}`)

export const encrypt = (payload) => adminApi('/encrypt').post(payload)

export const decrypt = (payload) => adminApi('/decrypt').post(payload)

export const createPayoutNote = (payload) => adminApi('/payout-note').post(payload)

export const cancelSubscription = (id) => adminApi(`/subscription/${id}/cancel`).post()
