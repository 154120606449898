import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export default {
  props: {
    type: {
      type: String,
      required: false,
      default: 'default'
    }
  },
  data() {
    return {
      termsLoading: false,
      noTerms: false,
      terms: ''
    }
  },
  created() {
    this.loadTerms()
  },
  methods: {
    async loadTerms() {
      this.termsLoading = true
      const entriesData = await this.$contentful.getEntries({
        limit: 1,
        content_type: 'setup',
        include: 10
      })

      if (entriesData.total === 0) {
        this.noTerms = true
        return
      }

      const setupEntry = entriesData.items[0]

      switch (this.type.toLowerCase()) {
        case 'lender':
          this.terms = documentToHtmlString(setupEntry.fields.lenderSetupTerms)
          break
        case 'sms':
          this.terms = documentToHtmlString(setupEntry.fields.textingSetupTerms)
          break
        case 'boss-assist':
          this.terms = documentToHtmlString(setupEntry.fields.bossAssistTerms)
          break
        case 'tc-assist':
          this.terms = documentToHtmlString(setupEntry.fields.tcAssistTerms)
          break
        case 'sierra':
          this.terms = documentToHtmlString(setupEntry.fields.sierra)
          break
        default:
          this.terms = documentToHtmlString(setupEntry.fields.setupTerms)
      }
    }
  }
}