<template>
  <div>
    <section class="bg-site-purple">
      <h3 class="text-center text-4xl font-bold leading-snug"><mark class="bg-white bg-opacity-30">Free CRM Audit</mark></h3>
      <p class="text-center text-lg mt-5 font-medium">Wondering if your CRM has the potential for improvement? Make use of our free account audit and receive a detailed 10-15 minute video analysis of your account.</p>
      <div class="text-center mt-10">
        <router-link class="text-black px-8 py-4 text-xl cursor-pointer font-semibold inline-block hover-bright bg-kts-gold" to="/audit">CRM Audit</router-link>
      </div>
      <p class="text-center mt-10 text-lg font-medium">Click ☝️ to sign up for a free CRM audit!</p>
    </section>
    <div v-if="crm === 'Follow Up Boss'">
      <hr>
      <section class="bg-site-purple">
        <h3 class="text-center text-4xl font-bold leading-snug"><mark class="bg-white bg-opacity-30">Free Guide to Setting Up Follow Up Boss for Success</mark></h3>
        <p class="text-center text-lg mt-5 font-medium">Streamline your business. Use our guide as a blueprint to set up Follow Up Boss.</p>
        <div class="text-center mt-10">
          <router-link class="text-black px-8 py-4 text-xl cursor-pointer font-semibold inline-block hover-bright bg-kts-gold" to="/solo">Solo Agent Guide</router-link>
        </div>
        <div class="text-center mt-5">
          <router-link class="text-black px-8 py-4 text-xl cursor-pointer font-semibold inline-block hover-bright bg-kts-gold" to="/team-lead">Team Lead Guide</router-link>
        </div>
        <p class="text-center mt-10 text-lg font-medium">Click ☝️ to access our free guide!</p>
      </section>
    </div>
  </div>
</template>
<script>
export default {
  props: ['crm']
}
</script>
<style scoped>
section {
  @apply py-20 px-6
}
</style>