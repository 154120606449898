<template>
  <section class="py-20 px-6 bg-gray-100 text-gray-700">
    <div class="container mx-auto">
      <h3 class="text-center text-4xl font-bold">Have remaining questions?</h3>
      <p class="mt-20 text-xl">If you have questions prior to signing up, use the Calendly widget below to schedule a call and we will be happy to chat. If you don't have questions and are ready to sign up, <a class="text-site-purple font-semibold underline cursor-pointer" @click="$emit('openSignUp')">click here</a> to get the ball rolling.</p>
      <div class="mt-10 lg:mt-0">
        <Calendly :url="calendarUrl" />
      </div>
    </div>
  </section>
</template>
<script>
import Calendly from '@/components/common/Calendly';
export default {
  props: ['calendarUrl'],
  components: { Calendly },
}
</script>