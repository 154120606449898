import wretch from 'wretch'

export default class FubApi {
  constructor(apiKey) {
    const rootUrl = 'https://api.followupboss.com/v1'

    const createEndpoint = (path) => (id = null) => `${path}${id ? `/${id}` : ''}`

    this.endpoints = {
      people: createEndpoint('/people'),
      actionPlans: createEndpoint('/actionPlans'),
      actionPlansPeople: createEndpoint('/actionPlansPeople'),
      users: createEndpoint('/users'),
      categories: createEndpoint('/categories')
    }

    this.api = (urlPath) => wretch(rootUrl + urlPath).auth(`Basic ${btoa(apiKey)}`).resolve((resolver) => resolver.json()).errorType('json')
  }

  getPeople = (query = {}) => this.api(this.endpoints.people()).query(query).get()

  getActionPlans = (query = {}) => this.api(this.endpoints.actionPlans()).query(query).get()

  getActionPlan = (id, query = {}) => this.api(this.endpoints.actionPlans(id)).query(query).get()

  updateActionPlan = (id, payload) => this.api(this.endpoints.actionPlans(id)).put(payload)

  getActionPlansPeople = (query = {}) => this.api(this.endpoints.actionPlansPeople()).query(query).get()

  applyActionPlan = ({ personId, actionPlanId }) => this.api(this.endpoints.actionPlansPeople()).post({ personId, actionPlanId })

  getUsers = (query = {}) => this.api(this.endpoints.users()).query(query).get()

  getCategories = (query = {}) => this.api(this.endpoints.categories()).query(query).get()
}