<template>
  <StandardPageLayout title="CRM Audit" :loading="isSubmitting" :success="success" :error="errorMessage">
    <p class="text-xl text-center mt-2">For <strong class="text-site-purple">Follow Up Boss</strong> or <strong class="text-site-purple">Sierra</strong> Users</p>
    <p class="text-lg mt-10 text-center">Our complimentary CRM audit features a detailed 10-15 minute video analysis of your account. In this video, we will highlight the strengths of your CRM and identify areas with potential for improvement.</p>
    <div class="mt-10 bg-gray-100 p-10 rounded-xl border border-gray-200 max-w-md mx-auto">
      <form @submit.prevent="submit">
        <p class="font-semibold">CRM Type</p>
        <SelectWrapper>
          <select v-model="formModel.crmType" :class="{ 'form-input-error': v$.formModel.crmType.$error, 'text-gray-400': !formModel.crmType }" class="form-input">
            <option disabled value="">Select CRM</option>
            <option value="fub">Follow Up Boss</option>
            <option value="sierra">Sierra</option>
          </select>
        </SelectWrapper>
        <p v-if="v$.formModel.crmType.$error" class="text-xs text-red-500">CRM type is required</p>
        <p class="font-semibold mt-10">Your Info</p>
        <VTextField :v="v$.formModel.firstName" placeholder="First Name" size="full" />
        <VTextField :v="v$.formModel.lastName" placeholder="Last Name" size="full" />
        <VTextField :v="v$.formModel.email" placeholder="Email" size="full" />
        <VTextField :v="v$.formModel.phone" placeholder="Phone" size="full" />
        <p class="font-semibold mt-10">Account Credentials</p>
        <p class="text-sm text-gray-500 mt-2">For security, we store passwords using encryption.</p>
        <p v-if="formModel.crmType === 'fub'" class="text-sm text-gray-500 mt-2">Please provide the owner account credentials.</p>
        <VTextField v-if="formModel.crmType === 'sierra'" :v="v$.formModel.siteName" placeholder="Sierra Site Name" />
        <VTextField :v="v$.formModel.crmUsername" placeholder="Username" autocomplete="off" size="full" />
        <VTextField :v="v$.formModel.crmPassword" placeholder="Password" autocomplete="off" size="full" />
        <p class="text-sm mt-10">By clicking "Submit," I authorize Kee Technology Solutions to log in and perform an account audit.</p>
        <button type="submit" class="button w-full">Submit</button>
      </form>
    </div>
    <template #success>
      <div class="text-center mt-10">
        <p class="text-lg">Thanks for signing up for our CRM audit!</p>
        <p class="text-lg mt-2">A confirmation email has been sent your way with audit details and next steps.</p>
        <button class="button mt-5" @click="$router.replace(formModel.crmType === 'sierra' ? '/sierra' : '/')">Done</button>
      </div>
    </template>
  </StandardPageLayout>
</template>
<script>
import { required, requiredIf, formValidationMixin } from '@/mixins/formValidateMixin'
export default {
  mixins: [formValidationMixin],
  data() {
    return {
      isSubmitting: false,
      success: false,
      errorMessage: "",
      agreeToConditions: "",
      formModel: {
        crmType: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        crmUsername: "",
        crmPassword: "",
        siteName: ""
      }
    }
  },
  validations() {
    return {
      formModel: {
        crmType: { required },
        firstName: { required },
        lastName: { required },
        email: { required },
        phone: { required },
        crmUsername: { required },
        crmPassword: { required },
        siteName: { required: requiredIf(this.formModel.crmType === 'sierra') }
      }
    }
  },
  methods: {
    async submit() {
      if (!(await this.v$.$validate())) return

      this.isSubmitting = true
      this.errorMessage = ""

      try {
        await this.$service.postAuditInquiry(this.formModel)
        this.success = true
      } catch (e) {
        this.errorMessage = this.$service.getErrorMessage(e)
      }
    }
  }
}
</script>