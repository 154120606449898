<template>
  <div class="mt-2">
    <SelectWrapper>
      <select
        autocomplete="country-name"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :class="{ 'form-input-error': errorMessage, 'text-gray-400': value === '' }"
        class="form-input"
      >
        <option disabled value="">Country</option>
        <option>United States</option>
        <option>Canada</option>
      </select>
    </SelectWrapper>
    <p v-if="errorMessage" class="text-xs text-red-500">{{ errorMessage }}</p>
  </div>
</template>
<script>
import SelectWrapper from './SelectWrapper'
export default {
  components: { SelectWrapper },
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: String,
      required: true,
    },
  },
}
</script>