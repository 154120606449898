
import FubApi from '../FubApi'
export default {
  data() {
    return {
      apiKey: '',
      fubApi: null,
    }
  },
  methods: {
    setupApi() {
      this.fubApi = new FubApi(this.apiKey)
    },
  }
}