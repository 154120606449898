<template>
  <Modal @close="$emit('close')">
    <div class="mt-5">
      <h1 class="center text-lg text-center font-semibold">Stage we make use of and their definitions</h1>
      <p class="mt-5 md:text-center">Noted below are the stages we use in our setup. Some stages you will already have in your FUB account and some will be new. Some stages might be similar to existing stages you already use and we might make recommendations to "merge" those stages into ours. We will never merge a stage of yours without your permission.</p>
      <div class="max-w-xl mx-auto mt-5 leading-8">
        <ul class="list-disc">
          <li v-for="stage, idx in stages" :key="idx" v-html="stage"></li>
        </ul>
      </div>
      <h1 class="center text-lg text-center font-semibold mt-10">Use Zillow Two Way Sync Stages?</h1>
      <p class="mt-5 md:text-center">We have a solution for this and make use of the Zillow defined stages for our setup. See the stages used by our setup in the following list</p>
      <div class="max-w-xl mx-auto mt-5">
        <ul class="list-disc leading-8">
          <li v-for="stage, idx in zillow" :key="idx" v-html="stage"></li>
        </ul>
      </div>
      <div class="text-center">
        <button class="button mt-5" @click="$emit('close')">Close</button>
      </div>
    </div>
  </Modal>
</template>
<script>
export default {
  computed: {
    stages() {
      return [
        '<strong>Lead:</strong> Lead not reached but continuing to try',
        '<strong>A - Hot 1-3 Months:</strong> Buying or selling in less than 3 months',
        '<strong>B - Warm 3-6 Months:</strong> Buying or selling in 3-6 months',
        '<strong>C - Cold 6+ Months:</strong> Buying or selling in 6+ months',
        '<strong>Renter - future buyer:</strong> Can’t buy now, but may be able to in the future',
        '<strong>Active Client:</strong> Current buyer or seller',
        '<strong>Pending:</strong> Offer accepted, waiting to close',
        '<strong>Past Client:</strong> Bought or sold with you & you want to keep in touch',
        '<strong>Sphere:</strong> Personal relationships',
        '<strong>Contact:</strong> No longer going to try, removed from smart lists, removed from drips. This can be people who have an agent, not interested, already bought, under your price point, out of your service  area, etc.',
        '<strong>Trash:</strong> “Delete”',
        '<strong>Closed:</strong> Past client you don’t want to stay in contact with',
        '<strong>Recruiting:</strong> Agent you’ve connected with to recruit to your team or brokerage',
      ]
    },
    zillow() {
      return [
        '<strong>Lead</strong> (syncs with Zillow status “New”): Lead not reached, no manual attempt made yet',
        '<strong>Attempted Contact:</strong> A lead you’re working to contact through phone calls, text and email.',
        '<strong>Spoke with Customer:</strong> A customer you’re speaking with but haven’t had a chance to meet.',
        '<strong>Appointment Set:</strong> An appointment has been scheduled with the buyer or seller to meet in-person or virtually.',
        '<strong>Met with Customer:</strong> A customer that you’ve met either in-person or virtually for a first appointment or tour.',
        '<strong>Showing Homes:</strong> A customer you’re helping tour listings in-person or virtually over multiple appointments.',
        '<strong>Listing Agreement:</strong> A listing agreement is in place for you to list the home of your seller.',
        '<strong>Active Listing:</strong> A client that has their home on the market and available for showings.',
        '<strong>Submitting Offers:</strong> A written offer has been submitted on a property for your client.',
        '<strong>Under Contract:</strong> Offer accepted',
        '<strong>Closed</strong> (Syncs with Zillow Status “Sale Closed”): This transaction has successfully closed',
        '<strong>Nurture:</strong> Apply this status for contacts who aren’t ready to tour homes yet or have slowed their search.',
        '<strong>Trash</strong> (Syncing with Zillow Status “Rejected”): Contacts you’ve determined will never transact and are ineligible to be worked as a lead.',
        '<strong>Sphere</strong> (not a Zillow stage): Personal friends and family members',
        '<strong>Recruiting</strong> (not a Zillow stage): Agent you’ve connected with to recruit to your team or brokerage'
      ]
    }
  }
}
</script>