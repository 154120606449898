<template>
  <FormPageLayout>
    <p class="text-2xl font-semibold text-site-purple">Thanks for signing up!</p>
    <p class="text-lg mt-2">We will need the following information before we can start revamping your Sierra.</p>
    <!-- Basic Info -->
    <FormSection v-if="!keeId" title="Your Info">
      <VTextField :v="v$.formModel.basicInfo.firstName" placeholder="First Name"/>
      <VTextField :v="v$.formModel.basicInfo.lastName" placeholder="Last Name"/>
      <VTextField :v="v$.formModel.basicInfo.email" placeholder="Email"/>
      <VTextField :v="v$.formModel.basicInfo.phone" placeholder="Phone Number"/>
    </FormSection>
    <!-- Sierra Credentials -->
    <FormSection title="Sierra Credentials">
      <VTextField :v="v$.formModel.siteName" placeholder="Site Name" />
      <VTextField :v="v$.formModel.crmUsername" placeholder="Username" autocomplete="off" />
      <VTextField :v="v$.formModel.crmPassword" placeholder="Password" autocomplete="off" />
    </FormSection>
    <!-- Brokerage -->
    <FormSection title="Brokerage">
      <VTextField :v="v$.formModel.brokerage" placeholder="i.e Keller Williams Realty" />
    </FormSection>
    <!-- Business Address -->
    <FormSection title="Business Address">
      <VTextField :v="v$.formModel.businessAddress.addressLine1" placeholder="Address Line 1" />
      <VTextField :v="v$.formModel.businessAddress.addressLine2" placeholder="Address Line 2" />
      <CountrySelect
        v-model="formModel.businessAddress.country"
        :errorMessage="v$.formModel.businessAddress.country.$error ? v$.formModel.businessAddress.country.$errors[0].$message : ''"
      />
      <VTextField :v="v$.formModel.businessAddress.city" placeholder="City" />
      <StateSelect
        v-model="formModel.businessAddress.state"
        :errorMessage="v$.formModel.businessAddress.state.$error ? v$.formModel.businessAddress.state.$errors[0].$message : ''"
        :showProvinces="formModel.businessAddress.country === 'Canada'"
      />
      <VTextField :v="v$.formModel.businessAddress.zip" :placeholder="formModel.businessAddress.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
    </FormSection>
    <!-- Servicing Multiple Markets -->
    <FormSection title="Do you service more than one market?" helperText="(multiple states, provinces, or regions)">
      <VRadio :v="v$.formModel.multiMarketServicing" />
    </FormSection>
    <!-- Online Reviews -->
    <FormSection title="Online Reviews" helperText="(Please provide any URLs to your online reviews i.e Google, Zillow, Facebook etc.)">
      <div v-for="(onlineReview, idx) in formModel.onlineReviews" :key="`online_review_${idx}`">
        <input type="text" v-model="onlineReview.url" class="form-input" placeholder="http://review-url.com" />
        <font-awesome-icon v-if="idx !== 0" class="cursor-pointer ml-2" @click="removeOnlineReview(idx)" icon="fa-solid fa-times" />
      </div>
      <p class="mt-5 font-semibold text-sm">Have more online review URLs?</p>
      <a @click="addOnlineReview" class="text-site-purple font-semibold mt-1 inline-block underline cursor-pointer">
        <font-awesome-icon icon="fa-solid fa-plus-circle" />
        Add Online Review URL
      </a>
    </FormSection>
    <!-- YouTube Channel -->
    <FormSection title="YouTube Channel">
      <VTextField :v="v$.formModel.youtube" placeholder="YouTube Channel URL" />
    </FormSection>
    <!-- BombBomb -->
    <FormSection title="Do you have BombBomb videos?">
      <VRadio :v="v$.formModel.hasBombBombVideos" />
    </FormSection>
    <FormSection v-if="formModel.hasBombBombVideos === 'Yes'" title="BombBomb Credentials">
      <VTextField :v="v$.formModel.bombbombUsername" placeholder="Username" autocomplete="off" />
      <VTextField :v="v$.formModel.bombbombPassword" placeholder="Password" autocomplete="off" />
    </FormSection>
    <!-- Fello -->
    <FormSection title="Include Fello Action Plans?" :helperText="`(Additional $${pricing.FELLO.toLocaleString('en')})`" chipText="Fello - Platform Pairing Add-On">
      <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using Fello</p>
      <ToggleSwitch v-model="formModel.includeFelloActionPlans" />
    </FormSection>
    <!-- Conversion Monster -->
    <FormSection title="Include Conversion Monster Action Plans?" :helperText="`(Additional $${pricing.CONVERSION_MONSTER.toLocaleString('en')})`" chipText="Conversion Monster - Platform Pairing Add-On">
      <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using Conversion Monster</p>
      <ToggleSwitch v-model="formModel.includeConversionMonsterActionPlans" />
    </FormSection>
    <!-- Ylopo Users -->
    <FormSection title="Include Ylopo Action Plans?" :helperText="`(Additional $${pricing.YLOPO.toLocaleString('en')})`" chipText="Ylopo - Platform Pairing Add-On">
      <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using Ylopo</p>
      <ToggleSwitch v-model="formModel.includeYlopoActionPlans" />
    </FormSection>
    <!-- Ylopo Users - Yes - Search Site Live -->
    <div v-if="formModel.includeYlopoActionPlans">
      <FormSection title="Is your Ylopo search site live?">
        <VRadio :v="v$.formModel.ylopoUserSearchSiteLive" />
      </FormSection>
      <FormSection title="Is your Ylopo branded website live?">
        <VRadio :v="v$.formModel.ylopoUserBrandedSiteLive" :options="['Yes', 'No', 'Not using']" />
      </FormSection>
      <FormSection v-if="formModel.ylopoUserBrandedSiteLive === 'Yes'" title="Ylopo branded website URL">
        <VTextField :v="v$.formModel.ylopoUserBrandedSiteUrl" placeholder="URL" autocomplete="off" />
      </FormSection>
    </div>
    <!-- Revamp Extended Add-on -->
    <FormSection title='Include add-on "Revamp Extended"?' :helperText="`(Additional $${pricing.REVAMP_EXTENDED.toLocaleString('en')})`" chipText="Revamp Extended Add-On">
      <p class="link underline text-base font-normal" @click="showRevampExtendedModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see what's included</p>
      <ToggleSwitch v-model="formModel.includeRevampExtended" />
      <Modal v-if="showRevampExtendedModal" @close="showRevampExtendedModal = false">
        <RevampExtended crm="Sierra" :price="pricing.REVAMP_EXTENDED.toLocaleString('en')" />
      </Modal>
    </FormSection>
    <!-- Ponds Add-on -->
    <FormSection title='Include Ponds add-on?' :helperText="`(Additional $${pricing.PONDS_ADD_ON.toLocaleString('en')})`" chipText="Ponds Add-On">
      <p>9 best practice ponds w/ cooresponding action plans for managing new leads, re-engagements, re-inquiries, resurrections, vendors, off markets, and referral agents.</p>
      <ToggleSwitch v-model="formModel.includePondsAddOn" />
    </FormSection>
    <!-- Spanish Content -->
    <FormSection title="Include add-on Spanish Action Plans?" :helperText="`(Additional $${pricing.SPANISH_ACTION_PLANS.toLocaleString('en')})`" chipText="Spanish Add-On">
      <p class="text-sm font-medium">Note: We do not provide Spanish translations for our Canadian market material.</p>
      <p class="text-sm font-medium">Note: After the setup is complete, your review will be required before we can go live with it.</p>
      <p><a class="link underline text-base font-normal" @click="showSpanishContentModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> more info</a></p>
      <SpanishContentModal v-if="showSpanishContentModal" @close="showSpanishContentModal = false" />
      <ToggleSwitch v-model="formModel.includeSpanishActionPlans" />

      <FormSection v-if="formModel.includeSpanishActionPlans" title="Default Language" embed="true">
        <p class="text-sm">Used when language preference is unknown for a lead</p>
        <VRadio :v="v$.formModel.defaultLanguage" :options="['English', 'Spanish']" />
      </FormSection>
    </FormSection>
    <!-- Lender Action Plans -->
    <FormSection title="Include add-on Lender Action Plans?" :helperText="`(Additional $${pricing.LENDER_ADD_ON.toLocaleString('en')})`" chipText="Lender Add-On">
      <p class="text-sm font-medium">Note: We do not provide Spanish translations for lender action plans at this time.</p>
      <p class="text-sm font-medium">Note: By making this selection you agree to the terms that the lenders in your Follow Up Boss account are in fact "Lender" type users. The lender action plans will not work otherwise.</p>
      <ToggleSwitch v-model="formModel.includeLenderAddOn" />

      <FormSection v-if="formModel.includeLenderAddOn" title="Do all lenders in your account work for the same mortgage company?" :embed="true">
        <VRadio :v="v$.formModel.singleMortgageCompany" />
      </FormSection>

      <FormSection v-if="formModel.singleMortgageCompany === 'Yes'" title="Lender Website" :embed="true">
        <VTextField :v="v$.formModel.lenderWebsiteUrl" placeholder="Website URL" />
      </FormSection>

      <FormSection
        v-if="formModel.singleMortgageCompany === 'Yes'"
        title="Online Reviews for Lender"
        helperText="(Please provide any URLs to reviews i.e Google, Facebook etc. Comma-seperated values)"
        :embed="true"
      >
        <VTextField :v="v$.formModel.lenderOnlineReviews" placeholder="URL" />
      </FormSection>
    </FormSection>
    <!-- Prcing -->
    <FormSection title="Project Price">
      <p class="text-xl mt-5">{{ totalDollarAmount }}</p>
      <p class="mt-2"><span class="link underline text-base font-normal" @click="showPriceBreakdownModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see price breakdown</span></p>
    </FormSection>
    <!-- Setup activation notice -->
    <FormSection title="Setup Activation">
      <p class="mt-5">After the setup of your new action plans and automations is complete, we will notify you via email. At this stage, you can either approve immediate activation or opt for a review period to evaluate the setup before it goes live.</p>
      <p class="mt-5"><strong>NOTE:</strong> Balance payment for the setup is due within two weeks from the setup completion date, irrespective of whether you choose immediate activation or a review period.</p>
    </FormSection>
    <FormSection title="Terms Agreement">
      <div class="mt-5">
        <div class="field" :class="{ 'field-block-error': v$.formModel.agreeToConditions.$error }">
          <p v-if="v$.formModel.agreeToConditions.$error" class="field-error text-xs text-red-500">{{ v$.formModel.agreeToConditions.$errors[0].$message }}</p>
          <input type="checkbox" v-model="formModel.agreeToConditions" true-value="agree" false-value="" />
          <label class="ml-2">I agree to <a class="link text-base font-normal" @click="showConditionAgreement = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> these conditions</a></label>
        </div>
      </div>
     </FormSection>
    <!-- Payment -->
    <FormSection title="Payment">
      <div v-if="subscription">
        <p class="mt-5">Payment Plan - $100 per month until project price is paid in full</p>
        <p class="mt-5">Due now is the first $100 installment payment. The remaining payments will auto charge exactly one month apart to the card provided below.</p>
      </div>
      <div v-else>
        <p class="mt-5">Due now is a deposit of {{ depositDollarAmount }} USD</p>
        <div class="bg-gray-200 mt-5 p-5">
          <p>The deposit amount is half of the total project price {{ totalDollarAmount }}.</p>
          <p class="mt-2">A balance payment of {{ depositDollarAmount }} will be due within 2 weeks after the setup is complete.</p>
          <p class="mt-2"><strong>NOTE:</strong> The two week window to pay balance <strong>IS NOT CONTINGENT</strong> on a follow up call with you after the setup. Two weeks is a hard deadline.</p>
        </div>
      </div>
      <p class="font-semibold mt-10">Billing Information</p>
      <VTextField :v="v$.formModel.billing.firstName" placeholder="First Name" />
      <VTextField :v="v$.formModel.billing.lastName" placeholder="Last Name" />
      <VTextField :v="v$.formModel.billing.email" placeholder="Email" />
      <VTextField :v="v$.formModel.billing.addressLine1" placeholder="Address Line 1" />
      <VTextField :v="v$.formModel.billing.addressLine2" placeholder="Address Line 2" />
      <CountrySelect
        v-model="formModel.billing.country"
        :errorMessage="v$.formModel.billing.country.$error ? v$.formModel.billing.country.$errors[0].$message : ''"
      />
      <VTextField :v="v$.formModel.billing.city" placeholder="City" />
      <StateSelect
        v-model="formModel.billing.state"
        :errorMessage="v$.formModel.billing.state.$error ? v$.formModel.billing.state.$errors[0].$message : ''"
        :showProvinces="formModel.billing.country === 'Canada'"
      />
      <VTextField :v="v$.formModel.billing.zip" :placeholder="formModel.billing.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
      <VTextField :v="v$.formModel.billing.phone" placeholder="Phone" />
      <p class="mt-5 font-semibold">Card Information</p>
      <SquarePayment ref="squarePayment" />
      <button @click="submit" :disabled="!canSubmit" class="button mt-5">{{ submitText }}</button>
      <p v-if="v$.$error" class="mt-2 text-red-500">
        Oops... There is an issue with the form. Check fields for errors.
      </p>
    </FormSection>
    <PriceBreakdownModal
      v-if="showPriceBreakdownModal"
      @close="showPriceBreakdownModal = false"
      :pricing="pricing"
      :pricingMeta="pricingMeta"
    />
    <ConditionAgreementModal v-if="showConditionAgreement" @close="showConditionAgreement = false" type="sierra" />
    <SubmittingModal
      v-if="isSubmitting"
      @close="(isSubmitting = false)"
      :loading="loading"
      :errorMessage="error"
      :showNextSteps="true"
      :receiptUrl="receiptUrl"
    >
      <template #success>
        <div>
          <p class="text-3xl text-site-purple font-semibold">Sign up complete!</p>
          <p class="text-xl pt-5" v-if="subscription">Your information has been submitted successfully and payment subscription has been set up.</p>
          <p class="text-xl pt-5" v-else>Your information has been submitted successfully and the deposit payment of <strong class="font-semibold">{{depositDollarAmount}}</strong> has been processed.</p>
        </div>
      </template>
    </SubmittingModal>
  </FormPageLayout>
</template>
<script>
import { required, requiredIf, formValidationMixin } from '@/mixins/formValidateMixin'
import _pick from 'lodash/pick'
import { SETUP_TYPE } from '../../../constants';
import { getPricing } from '@/pricing'
import SpanishContentModal from "../setup-form/SpanishContentModal";
import RevampExtended from '../../common/revamp/RevampExtended.vue';

const newOnlineReview = {
  url: ""
};
export default {
  mixins: [formValidationMixin],
  components: { SpanishContentModal, RevampExtended  },
  props: ["keeId"],
  data() {
    return {
      loading: false,
      error: "",
      isSubmitting: false,
      showConditionAgreement: false,
      showPriceBreakdownModal: false,
      showSpanishContentModal: false,
      showRevampExtendedModal: false,
      receiptUrl: "",
      formModel: {
        basicInfo: {
          firstName: "",
          lastName: "",
          email: "",
          phone: ""
        },
        siteName: "",
        crmUsername: "",
        crmPassword: "",
        onlineReviews: [{ ...newOnlineReview }],
        youtube: "",
        brokerage: "",
        businessAddress: {
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        includeSpanishActionPlans: false,
        defaultLanguage: "",
        includeLenderAddOn: false,
        singleMortgageCompany: "",
        lenderWebsiteUrl: "",
        lenderOnlineReviews: "",
        multiMarketServicing: "",
        includeFelloActionPlans: false,
        includeRevampExtended: false,
        includePondsAddOn: false,
        includeConversionMonsterActionPlans: false,
        includeYlopoActionPlans: false,
        hasBombBombVideos: "",
        bombbombUsername: "",
        bombbombPassword: "",
        ylopoUser: "",
        ylopoUserSearchSiteLive: "",
        ylopoUserBrandedSiteLive: "",
        ylopoUserBrandedSiteUrl: "",
        agreeToConditions: "",
        billing: {
          firstName: "",
          lastName: "",
          email: "",
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
        },
      },
    };
  },
  validations() {
    const requiredIfNoKeeId = { required: requiredIf(!this.keeId) }

    return {
      formModel: {
        basicInfo: {
          firstName: requiredIfNoKeeId,
          lastName: requiredIfNoKeeId,
          email: requiredIfNoKeeId,
          phone: requiredIfNoKeeId
        },
        siteName: { required },
        crmUsername: { required },
        crmPassword: { required },
        youtube: {},
        brokerage: { required },
        businessAddress: {
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
        },
        multiMarketServicing: { required },
        hasBombBombVideos: { required },
        bombbombUsername: { required: requiredIf(this.formModel.hasBombBombVideos === "Yes") },
        bombbombPassword: { required: requiredIf(this.formModel.hasBombBombVideos === "Yes") },
        ylopoUserSearchSiteLive: { required: requiredIf(this.formModel.includeYlopoActionPlans) },
        ylopoUserBrandedSiteLive: { required: requiredIf(this.formModel.includeYlopoActionPlans) },
        ylopoUserBrandedSiteUrl: { required: requiredIf(this.formModel.ylopoUserBrandedSiteLive === "Yes") },
        agreeToConditions: { required },
        defaultLanguage: { required: requiredIf(this.formModel.includeSpanishActionPlans) },
        singleMortgageCompany: { required: requiredIf(this.formModel.includeLenderAddOn) },
        lenderWebsiteUrl: { required: requiredIf(this.formModel.singleMortgageCompany === 'Yes') },
        lenderOnlineReviews: {},
        billing: {
          firstName: { required },
          lastName: { required },
          email: { required: requiredIf(this.subscription) },
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
          phone: {}
        },
      },
    };
  },
  computed: {
    setupType() {
      return SETUP_TYPE.SIERRA
    },
    totalAmount() {
      let amount = this.pricing.BASE
      if (this.formModel.includeSpanishActionPlans) {
        amount += this.pricing.SPANISH_ACTION_PLANS
      }
      if (this.formModel.includeLenderAddOn) {
        amount += this.pricing.LENDER_ADD_ON
      }
      if (this.formModel.includeRevampExtended) {
        amount += this.pricing.REVAMP_EXTENDED
      }
      if (this.formModel.includeFelloActionPlans) {
        amount += this.pricing.FELLO
      }
      if (this.formModel.includePondsAddOn) {
        amount += this.pricing.PONDS_ADD_ON
      }
      if (this.formModel.includeConversionMonsterActionPlans) {
        amount += this.pricing.CONVERSION_MONSTER
      }
      if (this.formModel.includeYlopoActionPlans) {
        amount += this.pricing.YLOPO
      }
      return amount
    },
    pricingMeta() {
      return {
        includeSpanishActionPlans: this.formModel.includeSpanishActionPlans,
        includeLenderAddOn: this.formModel.includeLenderAddOn,
        includeRevampExtended: this.formModel.includeRevampExtended,
        includePondsAddOn: this.formModel.includePondsAddOn,
        includeFelloActionPlans: this.formModel.includeFelloActionPlans,
        includeConversionMonsterActionPlans: this.formModel.includeConversionMonsterActionPlans,
        includeYlopoActionPlans: this.formModel.includeYlopoActionPlans
      }
    },
    pricing() {
      return getPricing(this.setupType, this.legacyPricing)
    },
    legacyPricing() {
      return this.$route.query.legacyPricing || ''
    },
    subscription() {
      return this.$route.query.subscription === 'true'
    },
    depositAmount() {
      return this.totalAmount/2
    },
    totalDollarAmount() {
      return `$${this.totalAmount.toLocaleString('en')}`
    },
    depositDollarAmount() {
      return `$${this.depositAmount.toLocaleString('en')}`
    },
    canSubmit() {
      return !this.isSubmitting;
    },
    submitText() {
      return this.subscription ? 'Submit and pay first installment of $100' : `Submit and pay ${this.depositDollarAmount} Deposit`
    }
  },
  methods: {
    openRouteInNewWindow(routeName) {
      window.open(this.$router.resolve({ name: routeName }).href, '_blank')
    },
    addOnlineReview() {
      this.formModel.onlineReviews.push({ ...newOnlineReview });
    },
    removeOnlineReview(idx) {
      this.formModel.onlineReviews.splice(idx, 1);
    },
    buildNotes() {
      return _pick(this.formModel, [
        'onlineReviews',
        'youtube',
        'brokerage',
        'businessAddress',
        'multiMarketServicing',
        'usesConversionMonster',
        'hasBombBombVideos',
        'bombbombUsername',
        'bombbombPassword',
        'ylopoUserSearchSiteLive',
        'ylopoUserBrandedSiteLive',
        'ylopoUserBrandedSiteUrl',
        'includeSpanishActionPlans',
        'defaultLanguage',
        'includeLenderAddOn',
        'singleMortgageCompany',
        'lenderWebsiteUrl',
        'lenderOnlineReviews',
        'includeConversionMonsterActionPlans',
        'includeYlopoActionPlans',
        'includeRevampExtended',
        'includePondsAddOn',
        'includeFelloActionPlans'
      ])
    },
    async submit() {
      if (!(await this.v$.$validate())) {
        console.log('Validation errors:', this.v$.$errors);
        return;
      }

      try {
        const notes = this.buildNotes();
        const billing = this.formModel.billing;
        const businessAddress = this.formModel.businessAddress
        const { locationId, sourceId } = await this.$refs.squarePayment.tokenize();
        this.error = ""
        this.isSubmitting = true
        this.loading = true

        const { receiptUrl } = await this.$service.createSetup({
          basicInfo: !this.keeId ? this.formModel.basicInfo : null,
          keeId: this.keeId,
          crmPassword: this.formModel.crmPassword,
          crmUsername: this.formModel.crmUsername,
          notes,
          meta: {
            setupType: this.setupType,
            legacyPricing: this.legacyPricing,
            subscription: this.subscription,
            sierraSiteName: this.formModel.siteName,
            includeSpanishActionPlans: this.formModel.includeSpanishActionPlans,
            includeLenderAddOn: this.formModel.includeLenderAddOn,
            includeRevampExtended: this.formModel.includeRevampExtended,
            includePondsAddOn: this.formModel.includePondsAddOn,
            includeConversionMonsterActionPlans: this.formModel.includeConversionMonsterActionPlans,
            includeYlopoActionPlans: this.formModel.includeYlopoActionPlans,
            includeFelloActionPlans: this.formModel.includeFelloActionPlans
          },
          payment: {
            note: 'Sierra Deposit Payment',
            isDeposit: true,
            billing,
            locationId,
            sourceId,
          },
          businessAddress
        })

        this.receiptUrl = receiptUrl
        this.loading = false
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
        this.loading = false
      }
    },
  },
};
</script>
<style scoped>
.field {
  @apply mt-2;
}

.field-block-error {
  @apply inline-block border border-red-500 p-2 rounded;
}
</style>
