<template>
  <section class="py-20 px-6 text-white" id="pricing">
    <div class="container mx-auto">
      <h3 class="text-center text-4xl font-bold">Curious about pricing?</h3>
      <div class="mt-20 bg-gray-100 text-gray-700 p-10 lg:p-20 rounded-xl border-8 border-kts-gold">
        <p class="text-center text-3xl font-semibold">BossAssist Training</p>
        <p class="text-center text-xl mt-3 font-medium">Virtual Assistant Sign Up - Tiered Pricing</p>
        <p class="text-center mt-3">
          <router-link to="/terms/boss-assist" class="link">View Terms</router-link>
        </p>
        <div v-for="{ description, price } in pricingTiers" :key="`tier_${price}`" class="text-center mt-8">
          <p><span class="bg-site-purple text-white rounded-full px-3 py-1 text-sm font-semibold">{{ description }}</span></p>
          <p class="mt-2"><span class="text-3xl font-semibold">${{ price.toLocaleString('en') }}</span> <span class="text-xl">USD</span> <span class="text-base font-normal">(per VA)</span></p>
        </div>
        <hr />
        <p class="text-center text-2xl">Just some basic information is needed to get the ball rolling!</p>
        <div class="text-center mt-10">
          <a class="bg-kts-gold text-black uppercase font-semibold py-4 px-10 text-lg inline-block cursor-pointer tracking-wider hover-bright" @click="$emit('openSignUp')">Get Started</a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { bossAssistPricingTiers } from '../../../pricing'
export default {
  computed: {
    pricingTiers() {
      return bossAssistPricingTiers
    }
  }
}
</script>
<style scoped>
hr {
  @apply border-gray-300 my-14;
}
</style>