<template>
  <div class="container mx-auto p-10">
    <div v-if="error">
      <p>{{ error }}</p>
      <button class="button mt-5" @click="error = false; showForm = true;">Back</button>
    </div>
    <div v-else-if="loading">
      <p class="text-center text-4xl">LOADING...</p>
    </div>
    <div v-else-if="showForm" class="flex flex-col max-w-sm mx-auto bg-gray-700 p-10">
      <input type="text" placeholder="api key" v-model="formModel.fubApiKey" />
      <select v-model="formModel.templateType">
        <option value="" disabled>Select template type</option>
        <option value="KTS">KTS</option>
        <option value="TC">TC</option>
        <option value="ANY">ANY</option>
      </select>
      <input type="text" placeholder="search" v-model="formModel.searchString" />
      <input type="text" placeholder="replace" v-model="formModel.replaceString" />
      <div class="flex mt-2"><label class="text-white mr-2">Replace Subject:</label><input type="checkbox" @change="formModel.replaceSubject = !formModel.replaceSubject" /></div>
      <button class="button mt-5" @click="preview" :disabled="!canSubmit">Preview</button>
    </div>
    <div v-else-if="emailTemplates.length < 1">
      <p class="text-center text-xl">No results</p>
      <div class="text-center">
        <p class="button mt-5" @click="showForm = true">Back to search</p>
      </div>
    </div>
    <div v-else>
      <div class="flex justify-between items-center">
        <div class="flex">
          <button class="bg-gray-700 py-2 px-5 text-white rounded mr-10" @click="showForm = true">Back to search</button>
          <h1 class="text-2xl">{{ emailTemplates.length - updatedTemplates.length }} Email Templates to Update</h1>
        </div>
        <button v-if="updatedTemplates.length < emailTemplates.length" class="bg-gray-700 py-2 px-5 text-white rounded" @click="updateAll">Update all</button>
      </div>
      <div v-for="emailTemplate in emailTemplates" :key="emailTemplate.id" class="mt-5">
        <div class="flex justify-between bg-gray-700 px-5 py-2">
          <h1 class="text-white font-bold">{{ emailTemplate.name }}</h1>
          <div v-if="updatedTemplates.find(id => id === emailTemplate.id)" class="px-2 bg-green-500 rounded">Updated</div>
          <div v-else class="flex">
            <button class="text-white border rounded px-2 mr-5" @click="updateTemplate(emailTemplate.id)">Update</button>
            <button class="text-white border rounded px-2" @click="removeTemplate(emailTemplate.id)">Discard</button>
          </div>
        </div>
        <div v-if="formModel.replaceSubject" v-html="emailTemplate.subject"></div>
        <div v-else-if="!updatedTemplates.find(id => id === emailTemplate.id)" v-html="emailTemplate.body" class="border border-gray-700 p-10 text-sm"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      error: "",
      loading: false,
      formModel: {
        fubApiKey: '',
        templateType: '',
        searchString: '',
        replaceString: '',
        replaceSubject: false,
      },
      emailTemplates: [],
      updatedTemplates: [],
      showForm: true,
    }
  },
  computed: {
    canSubmit() {
      const { fubApiKey, searchString, replaceString, templateType } = this.formModel
      return fubApiKey && searchString && replaceString && templateType
    },
  },
  methods: {
    async preview() {
      try {
        const { fubApiKey, searchString, replaceString, replaceSubject, templateType } = this.formModel
        const payload = {
          searchString,
          replaceString,
          replaceSubject,
          templateType,
        }
        this.loading = true
        this.showForm = false
        const { templates } = await this.$service.api('/external-fub/email-templates/preview-find-replace').headers({ 'x-external-fub-api-key': fubApiKey }).post(payload)
        this.loading = false
        this.emailTemplates = templates
        this.updatedTemplates = []
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
        this.loading = false
      }
    },
    async updateTemplate(templateId) {
      const { fubApiKey, searchString, replaceString, replaceSubject } = this.formModel
      const payload = {
        templateId,
        searchString,
        replaceString,
        replaceSubject
      }
      this.loading = true
      await this.$service.api('/external-fub/email-templates/update-find-replace').headers({ 'x-external-fub-api-key': fubApiKey }).post(payload)
      this.loading = false
      this.updatedTemplates.push(templateId)
    },
    async updateAll() {
      try {
        if (!confirm('Are you sure?')) return
        const { fubApiKey, searchString, replaceString, replaceSubject, templateType } = this.formModel
        const payload = {
          templateIdList: this.emailTemplates.map(({ id }) => id),
          searchString,
          replaceString,
          replaceSubject,
          templateType
        }
        this.loading = true
        await this.$service.api('/external-fub/email-templates/update-all-find-replace').headers({ 'x-external-fub-api-key': fubApiKey }).post(payload)
        this.loading = false
        this.updatedTemplates = this.emailTemplates.map(({ id }) => id)
      } catch (e) {
        this.error = e
        this.loading = false
      }
    },
    removeTemplate(templateId) {
      this.emailTemplates = this.emailTemplates.filter(({ id }) => id !== templateId)
    }
  }
}
</script>
<style scoped>
input[type=text] {
  @apply border rounded py-1 px-2 mt-2
}

select {
  @apply border rounded py-1 px-2 mt-2
}
</style>