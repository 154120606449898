<template>
  <div v-html="html" class="contentful-rte-html" />
</template>
<script>
export default {
  props: ['html']
}
</script>
<style>
.contentful-rte-html {
  & p {
    @apply leading-8 mb-3;
  }
  & ul {
    @apply list-disc;

    & ul, ol {
      @apply ml-10;
    }
  }
  & ol {
    @apply list-decimal;

    & ol, ul {
      @apply ml-10;
    }
  }
  & h1 {
    @apply text-3xl mb-5;
  }
  & h2 {
    @apply text-2xl mb-4;
  }
  & h3 {
    @apply text-xl mb-3;
  }
  & a {
    @apply text-site-purple underline cursor-pointer;
  }
}
</style>