<template>
  <StandardPageLayout>
    <ContentfulRTE :html="terms" />
  </StandardPageLayout>
</template>
<script>
import TermsMixin from '@/mixins/termsMixin'

export default {
  mixins: [TermsMixin],  
}
</script>