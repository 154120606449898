<template>
  <PromoLayout promo="Seven Pond Guide" buttonText="GET THE GUIDE!">
    <div class="text-2xl uppercase text-center">
      <div>STEP-BY-STEP GUIDE</div>
      <div class="bg-black text-white font-semibold py-1 px-3 inline-block mt-2">SEVEN PONDS</div>
      <div class="mt-2">TO UTILIZE IN FOLLOW UP BOSS</div>
    </div>
    <p class="text-lg mt-5"><mark class="bg-purple-300">Enter your info below to receive the guide for <strong class="font-bold">FREE!</strong></mark></p>
  </PromoLayout>
</template>
<script>
import PromoLayout from '../../layouts/PromoLayout.vue';

export default {
  components: { PromoLayout }
}
</script>