<template>
  <StandardPageLayout title="Our Blog Posts">
    <div class="flex flex-wrap mt-20 -m-3">
      <div v-for="post in blogPosts" class="w-full sm:w-1/2 lg:w-1/3 flex-shrink-0 flex-grow-0 p-3" :key="post.sysId">
        <router-link :to="{ name: 'post', params: { slug: post.slug } }" class="bg-white shadow-lg block transform hover:-translate-y-5 transition ease-in-out">
          <img :src="post.imageUrl" />
          <div class="p-5">
            <h2 class="text-xl">{{ post.title }}</h2>
            <p class="mt-2 uppercase text-sm">{{ post.date }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </StandardPageLayout>
</template>
<script>
import { isoToMonthDayYear } from '../../../utils'
export default {
  data() {
    return {
      loading: false,
      blogPosts: []
    }
  },
  async created() {
    this.loading = true
    try {
      const entries = await this.$contentful.getEntries({
        content_type: 'blogPost',
        include: 10,
        order: '-sys.createdAt'
      })

      this.blogPosts = entries.items.map(({ fields: { title, slug, image }, sys: { id: sysId, createdAt } }) => ({
        sysId,
        title,
        slug,
        date: isoToMonthDayYear(createdAt),
        imageUrl: image.fields.file.url
      }))
      
      this.loading = false
    } catch (error) {
      this.loading = false
      console.error(error)
    }
  }
}
</script>