<template>
  <div class="mt-2">
    <SelectWrapper>
      <select
        :value="value"
        @input="$emit('input', $event.target.value)"
        :class="{ 'form-input-error': errorMessage, 'text-gray-400': value === '' }"
        class="form-input"
      >
        <option disabled value="">{{ showProvinces ? 'Province / Territory' : 'State' }}</option>
        <option v-for="item in items" :key="item" :value="item">{{ item }}</option>
      </select>
    </SelectWrapper>
    <p v-if="errorMessage" class="text-xs text-red-500">{{ errorMessage }}</p>
  </div>
</template>
<script>
import { US_STATES, CANADA_PROVINCES } from '@/constants'
import SelectWrapper from './SelectWrapper'
export default {
  components: { SelectWrapper },
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: String,
      required: true,
    },
    showProvinces: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    showProvinces() {
      this.$emit('input', '')
    }
  },
  computed: {
    items() {
      return this.showProvinces ? CANADA_PROVINCES : US_STATES
    }
  }
}
</script>