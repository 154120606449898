<template>
  <TextureBackground>
    <div class="max-w-xl mx-auto p-8 sm:p-10 flex flex-col items-center">
      <router-link :to="{ name: 'home' }"><Logo /></router-link>
      <div class="bg-white p-8 sm:p-10 mt-10 border-kts-gold rounded-xl border-8">
        <p v-if="error" class="mt-10">Oops. Something went wrong. Please contact <MailToLink subject="Guide Form Submission Error" :body="`There was an issue submitting my info to download the ${title} Guide`" /></p>
        <div v-else-if="redeemed" class="mt-10">
          <p>Thank you! The guide has been emailed to {{ formModel.email }}</p>
          <div class="flex justify-center">
            <button @click="$router.replace(`/`)" class="bg-kts-gold mt-5 p-3 shadow-lg hover:opacity-90 text-xl font-semibold uppercase">Done</button>
          </div>
        </div>
        <form v-else @submit.prevent="submit" class="flex flex-col font-medium">
          <h1 class="text-2xl"><strong class="font-semibold">Streamline</strong> your business.<br><strong class="font-semibold">Boost your success</strong> as a {{ title.toLowerCase() }}.<br></h1>
          <p class="text-lg mt-5">Enter your info below to receive the <mark class="bg-purple-300"><strong>{{ title }} Guide to setting up Follow Up Boss for Success</strong></mark> for <strong class="font-bold">FREE!</strong></p>
          <input type="text" placeholder="First Name" v-model="formModel.firstName" />
          <input type="text" placeholder="Last Name" v-model="formModel.lastName" />
          <input type="text" placeholder="Email" v-model="formModel.email" />
          <input type="text" placeholder="Phone" v-model="formModel.phone" />
          <button type="submit" :disabled="!canSubmit" class="bg-kts-gold mt-5 p-3 shadow-lg hover:opacity-90 text-xl font-semibold uppercase">Get the guide!</button>
        </form>
      </div>
    </div>
  </TextureBackground>
</template>
<script>
import { GUIDE_CODES } from '@/constants'
import TextureBackground from '@/components/common/TextureBackground.vue'

export default {
  props: {
    guideType: {
      type: String,
      required: true
    }
  },
  components: {
    TextureBackground
  },
  data() {
    return {
      redeemed: false,
      error: false,
      formModel: {
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      }
    }
  },
  computed: {
    title() {
      return this.guideType === GUIDE_CODES.SOLO ? 'Solo Agent' : 'Team Lead'
    },
    canSubmit() {
      const { firstName, lastName, email, phone } = this.formModel
      return !!firstName && !!lastName && !!email && !!phone
    },
  },
  methods: {
    submit() {
      this.error = false
      this.$service.redeemGuide({
        guideCode: this.guideType,
        ...this.formModel
      })
        .then(() => this.redeemed = true)
        .catch(() => this.error = true)
    }
  }
}
</script>
<style scoped>
button:disabled {
  @apply opacity-50 cursor-not-allowed;
}
input[type=text] {
  @apply border-2 px-5 py-1 mt-3 w-full h-12 text-lg font-medium;
}
input[type=text]:focus {
  @apply outline-none border-purple-400;
}
</style>
