<template>
  <div class="mt-2">
    <span class="toggle-wrapper inline-block relative cursor-pointer rounded-full" role="checkbox" :aria-checked="value.toString()" tabindex="0" @click="toggle" @keydown.space.prevent="toggle">
      <span class="toggle-background inline-block rounded-full h-full w-full" :class="this.value ? 'bg-site-purple': 'bg-gray-400'" />
      <span class="toggle-indicator absolute left-0 bottom-0 border border-gray-300 shadow rounded-full bg-white" :style="{ transform: value ? 'translateX(20px)' : 'translateX(0)' }" />
    </span>
  </div>
</template>
<script>
export default {
  props: {
    value:{
      type: Boolean,
      required: true
    }
  },
  methods: {
    toggle() {
      this.$emit('input', !this.value);
    }
  }
};
</script>
<style scoped>
.toggle-wrapper {
  width: 40px;
  height: 20px;
}
.toggle-background {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color .4s ease;
}
.toggle-indicator {
  height: 20px;
  width: 20px;
  transition: transform .4s ease;
}
</style>