<template>
  <StandardPageLayout>
    <div class="text-center uppercase">
      <h1 class="text-4xl font-medium">Fourty Seven</h1>
      <h1 class="text-kts-gold font-bold text-4xl mt-2">CRM Tasks</h1>
      <h1 class="bg-black text-white py-2 px-5 inline-block font-medium text-3xl mt-2">For Database Management</h1>
    </div>
    <div class="text-center mt-20 text-3xl">
      <font-awesome-icon icon="fa-solid fa-laptop" />
      <h2>CRM Tasks</h2>
    </div>
    <div class="bg-gray-300 p-10 max-w-2xl mx-auto mt-10 shadow-lg">
      <h2 class="text-3xl">Easy</h2>
      <ul class="list-disc mt-5">
        <li v-for="task, idx in easyTasks" :key="`easy_task_${idx}`" class="text-lg leading-loose">{{ task }}</li>
      </ul>
    </div>
    <div class="bg-gray-300 p-10 max-w-2xl mx-auto mt-10 shadow-lg">
      <h2 class="text-3xl">Medium</h2>
      <ul class="list-disc mt-5">
        <li v-for="task, idx in mediumTasks" :key="`medium_task_${idx}`" class="text-lg leading-loose">{{ task }}</li>
      </ul>
    </div>
    <div class="bg-gray-300 p-10 max-w-2xl mx-auto mt-10 shadow-lg">
      <h2 class="text-3xl">Hard</h2>
      <ul class="list-disc mt-5">
        <li v-for="task, idx in hardTasks" :key="`hard_task_${idx}`" class="text-lg leading-loose">{{ task }}</li>
      </ul>
    </div>
    <div class="text-center mt-20">
      <a href="https://dkdyfejjuotsvmtvfynr.supabase.co/storage/v1/object/public/kts-public-files/CRM%20Tasks%20-%20Database%20Management.pdf" target="_blank" class="link text-lg"><font-awesome-icon icon="fa-solid fa-download" /> Download Tasks PDF</a>
    </div>
    <div class="bg-black text-white mt-20 p-10 text-center">
      <h2 class="text-3xl font-medium">Prefer not to learn CRM management by yourself?</h2>
      <p class="leading-relaxed mt-5">Hire our team to train you, your assistant, your admin, and/or your ops manager on Follow Up Boss. They will become database managers so that you can delegate tasks & grow your business.</p>
      <router-link to="/boss-assist"><button class="button mt-5">LEARN ABOUT BossAssist</button></router-link>
      <br>
      <a class="button mt-5" :href="calendarUrl" target="_blank">SCHEDULE A CALL</a>
    </div>
  </StandardPageLayout>
</template>
<script>
import { buildCalendarUrl } from '../../../utils'
import { CALENDLY_CALENDARS } from '../../../constants'

export default {
  props: ['keeId'],
  async created() {
    await this.$service.funnelTagging({ keeId: this.keeId, funnel: '47 Tasks' })
  },
  computed: {
    calendarUrl() {
      return buildCalendarUrl({
        calendar: CALENDLY_CALENDARS.BOSS_ASSIST_DISCOVERY,
        utm: {
          utmCampaign: 'BossAssist',
          utmSource: 'BossAssist - Fourty Seven Tasks',
          utmMedium: 'Discovery Call'
        }
      })
    },
    easyTasks: () => [
      'Manually create leads for you',
      'Deleting a Lead Source',
      'How to Create and Manage Appointments (Owner Accounts only)',
      "Update agent's activity & create report",
      'Take care of misc. tasks assigned to them in FUB',
      'How to view Appointment Data for Reporting',
      'Update email signature',
      'Customize your notifications',
      'Download app - Android',
      'Download app - iPhone',
      'Organize your teams',
      'Adjust power ups',
      'Agent hotline to pause leads',
      'Add files',
      'Reorganize, remove and re-order smart lists',
      'Keep tags clean & uniform',
    ],
    mediumTasks: () => [
      'Keep agents accountable & ping them to warn them',
      'Re-assign leads as consequence or pause leads',
      'Schedule, Confirm, & Reschedule Appointments',
      'Review your logged calls, transcribe recording, change stage, and if needed assign task if action is needed',
      'How to add a Deal from the Contact Record',
      'Business Profile Registration',
      'Update content in action plans with new videos/blogs',
      'Delete a lead source',
      'Onboard a new agent',
      'Adjust stages & merge (Owner Account Only)',
      'How to Customize Custom Fields and Add Headers (Owner Account Only)',
      'Delete agent & reassign leads',
      'Updating the Deal Stage as the Transaction Progresses',
      'How to Edit Smart List Filters',
      'How to Create a New Smart List',
      'Create email templates (and folders)',
      'Create action plans',
      'Adjust advanced rules & distribution in Lead Flow',
      'Send batch emails',
      'Track referrals from VIP clients and network, then send gifts at benchmarks',
      'Check on "Unsubscribed" client who re-registers or has a New Inquiry; ensures they receive necessary emails'
    ],
    hardTasks: () => [
      'Manage your inbox & respond to client',
      'Take incoming calls/be receptionist for inboxes',
      'Report on leads claimed in pond',
      'Scrub database for bad emails',
      'Segment your database for batch emails - new listing, open houses, just sold, etc',
      'Create automations',
      'Correct leads that are sourced "unspecified" so everyone is sourced properly & reporting is accurate',
      'Track outbound referrals for agent compatibility with agent/payout',
      'Check on Carrier Violations for Text messages',
      'Monitor potentially missed emails/texts for other agents via smart list'
    ]
  }
}
</script>