<template>
  <div class="text-center">
    <h1 class="text-xl">Affiliate Payout Note</h1>
    <p v-if="success" class="mt-10 inline-block bg-white p-5">Note created successfully</p>
    <div v-else>
      <input type="text" v-model="keeId" placeholder="Affiliate Kee Id" class="form-input" />
      <br>
      <input type="text" v-model="clientName" placeholder="Name of client that signed up" class="form-input" />
      <br>
      <input type="text" v-model="payoutAmount" placeholder="Payout amount" class="form-input" />
      <br>
      <input type="text" v-model="status" placeholder="Payment Status" class="form-input" />
      <br>
      <button class="button mt-5" :disabled="incomplete" @click="submit">Submit</button>
      <p class="mt-5 text-red-500">{{ error }}</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      keeId: '',
      clientName: '',
      payoutAmount: '',
      status: '',
      error: '',
      success: false,
    }
  },
  computed: {
    incomplete() {
      return !this.keeId || !this.clientName || !this.payoutAmount || !this.status
    }
  },
  methods: {
    async submit() {
      this.error = ''
      try {
        const { keeId, clientName, payoutAmount, status } = this
        await this.$service.createPayoutNote({ keeId, clientName, payoutAmount: payoutAmount.replace('$', ''), status })
        this.success = true
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
      }
    }
  }
}
</script>