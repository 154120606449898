import ToastComponent from '../components/common/Toast'

const Toast = {
  install(Vue) {

    this.EventBus = new Vue()
    
    Vue.component('Toast', ToastComponent)

    Vue.prototype.$toast = {
      show(text, timeInSeconds) {
        Toast.EventBus.$emit('show', { text, timeInSeconds })
      }
    }
  }
}

export default Toast