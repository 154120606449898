<template>
  <section :class="{'py-20 px-6 bg-site-gray text-white': !simplified}" id="testimonials">
    <div class="container mx-auto">
      <h3 v-if="!simplified" class="text-center text-4xl font-bold">Client Testimonials</h3>
      <div class="flex flex-wrap -m-3" :class="{'mt-20': !simplified}">
        <div v-for="video in videoTestimonials" :key="video.videoIdentifier" class="w-full sm:w-1/2 lg:w-1/3 flex-shrink-0 flex-grow-0 p-3">
          <Video :videoId="video.videoIdentifier" :videoType="video.type" />
        </div>
      </div>
      <a v-if="!simplified" class="mt-20 flex justify-center items-center border border-kts-gold p-5 hover-bright cursor-pointer" href="https://www.facebook.com/KeeTechnologySolutions/" target="_blank">
        <div class="bg-kts-gold rounded-full mr-5 flex-shrink-0">
          <img :src="require('@/assets/images/facebook.svg')" class="transform scale-75" />
        </div>
        <p class="text-xl lg:text-3xl">We have <span class="font-bold">over 90 five star reviews</span> on <span class="text-kts-gold underline">Facebook</span></p>
      </a>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: ['simplified'],
  computed: {
    ...mapGetters(['videoTestimonials'])
  }
}
</script>