<template>
  <Modal @close="$emit('close')">
    <ContentfulRTE :html="terms" />
    <div class="text-center">
      <button class="button mt-5" @click="$emit('close')">Close</button>
    </div>
  </Modal>
</template>
<script>
import TermsMixin from '@/mixins/termsMixin'
export default {
  mixins: [TermsMixin]
}
</script>
