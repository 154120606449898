<template>
  <StandardPageLayout :transparentMain="true">
    <div class="max-w-xl mx-auto">
      <div class="bg-white p-8 sm:p-10 border-kts-gold rounded-xl border-8">
        <div class="text-2xl uppercase text-center">
          <div>FOLLOW UP BOSS</div>
          <div class="bg-black text-white font-semibold py-1 px-3 inline-block mt-2">CRASH COURSE</div>
        </div>
        <p class="text-center mt-5 text-kts-gold"><font-awesome-icon icon="fa-solid fa-video" /></p>
        <p class="font-semibold test-gary-500 text-center">Kee Technology Solutions<br>Video Training Course</p>
        <p class="mt-5">You're one step away from transforming how you (and your team) learn and approach Follow Up Boss!</p>
        <p class="mt-5">Gain access to our video training course to level up your CRM skills by submitting the payment form below.</p>
        <p class="mt-5">One Time Enrollment Fee - $49 (USD)</p>
        <form @submit.prevent="submit" class="mt-5">
          <p class="font-semibold">Payment Info</p>
          <VTextField :v="v$.formModel.billing.firstName" placeholder="First Name" />
          <VTextField :v="v$.formModel.billing.lastName" placeholder="Last Name" />
          <VTextField :v="v$.formModel.billing.email" placeholder="Email" />
          <VTextField :v="v$.formModel.billing.phone" placeholder="Phone" />
          <VTextField :v="v$.formModel.billing.addressLine1" placeholder="Address Line 1" />
          <VTextField :v="v$.formModel.billing.addressLine2" placeholder="Address Line 2" />
          <CountrySelect
            v-model="formModel.billing.country"
            :errorMessage="v$.formModel.billing.country.$error ? v$.formModel.billing.country.$errors[0].$message : ''"
          />
          <VTextField :v="v$.formModel.billing.city" placeholder="City" />
          <StateSelect
            v-model="formModel.billing.state"
            :errorMessage="v$.formModel.billing.state.$error ? v$.formModel.billing.state.$errors[0].$message : ''"
            :showProvinces="formModel.billing.country === 'Canada'"
          />
          <VTextField :v="v$.formModel.billing.zip" :placeholder="formModel.billing.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
          <p class="mt-5 font-semibold">Card Information</p>
          <SquarePayment ref="squarePayment" class="mt-2" />
          <button class="button" type="submit">Submit & Enroll</button>
          <SubmittingModal
            v-if="isSubmitting"
            @close="isSubmitting = false"
            :loading="loading"
            :errorMessage="error"
            :receiptUrl="receiptUrl"
          >
            <template #success>
              <h2 class="text-lg font-semibold">🎉 You're Enrolled! 🎉</h2>
              <p class="mt-5">We've emailed the course link to {{ formModel.billing.email }}</p>
            </template>
          </SubmittingModal>
        </form>
      </div>
    </div>
  </StandardPageLayout>
</template>
<script>
import { required, formValidationMixin } from '@/mixins/formValidateMixin'
import SquarePayment from '../common/SquarePayment.vue'
export default {
  mixins: [formValidationMixin],
  components: { SquarePayment },
  props: ['course'],
  data() {
    return {
      loading: false,
      error: false,
      isSubmitting: false,
      receiptUrl: "",
      formModel: {
        billing: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
      }
    }
  },
  validations() {
    return {
      formModel: {
        billing: {
          firstName: { required },
          lastName: { required },
          email: { required },
          phone: { required },
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
        }
      }
    }
  },
  methods: {
    async submit() {
      if (!(await this.v$.$validate())) return
      this.error = false
      this.isSubmitting = true
      this.loading = true
      try {
        const { billing } = this.formModel
        const { locationId, sourceId } = await this.$refs.squarePayment.tokenize()
        
        const { receiptUrl = "" } = await this.$service.postCourseEnrollment({
          course: this.course,
          basicInfo: {
            firstName: billing.firstName,
            lastName: billing.lastName,
            email: billing.email,
            phone: billing.phone
          },
          payment: {
            note: 'FUB Crash Course Enrollment',
            locationId,
            sourceId,
            billing
          }
        })
        
        this.receiptUrl = receiptUrl
        this.loading = false
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
        this.loading = false
      }
    }
  }
}
</script>