<template>
  <form @submit.prevent="submit" class="flex flex-col items-center">
    <div>
      <div class="mt-5">
        <label class="block">First Name</label>
        <input type="text" v-model="formModel.firstName" class="border border-gray-300 p-1 rounded w-60" name="firstName" />
      </div>
      <div class="mt-5">
        <label class="block">Last Name</label>
        <input type="text" v-model="formModel.lastName" class="border border-gray-300 p-1 rounded w-60" name="lastName" />
      </div>
      <div class="mt-5">
        <label class="block">Email</label>
        <input type="email" v-model="formModel.email" class="border border-gray-300 p-1 rounded w-60" name="email" />
      </div>
      <div class="mt-5">
        <label class="block">Phone Number</label>
        <input type="tel" v-model="formModel.phone" class="border border-gray-300 p-1 rounded w-60" name="phone" />
      </div>
    </div>
    <button type="submit" :disabled='isDisabled'
      class="bg-kts-gold text-white py-4 px-12 rounded inline-block mt-10 text-xl"
      :class="{ 'opacity-50 cursor-not-allowed': isDisabled }">Submit</button>
    <p v-if="error" class="text-red-500 mt-2">{{ error }}</p>
    <div class="text-sm text-center mt-5 font-light">
      <p>We have a strict NO-SPAM policy. We will never rent or sell your information.</p>
      <p class="mt-2">By clicking submit you agree to be contacted by Kee Technology Solutions via call, email, and text. To opt out, you can reply ‘stop’ at any time or click the unsubscribe link in the emails. Message and data rates may apply.</p>
    </div>
  </form>
</template>
<script>
export default {
  props: {
    error: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      formModel: {
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
      }
    }
  },
  computed: {
    isDisabled() {
      return !this.formModel.firstName || !this.formModel.lastName || !this.formModel.email || !this.formModel.phone
    },
  },
  methods: {
    submit() {
      this.$emit('form-submit', this.formModel)
    }
  }
}
</script>