<template>
  <div class="mt-2" :class="{'border border-red-500 p-2 rounded': v.$error}">
    <div v-for="option in radioOptions" :key="option.value" class="mt-1">
      <input type="radio" :value="typeof option === 'string' ? option : option.value" v-model="v.$model" class="mr-2" />
      <label v-html="typeof option === 'string' ? option : option.label" /><span v-if="option.helperText" class="text-sm text-gray-700 ml-2" v-html="option.helperText"></span>
    </div>
    <p v-if="v.$error" class="text-xs text-red-500">{{ v.$errors[0].$message }}</p>
  </div>
</template>
<script>
export default {
  props: ['v', 'options'],
  computed: {
    radioOptions() {
      return this.options || ['Yes', 'No']
    }
  }
}
</script>