export default [
  {
    text: 'Benefits',
    scrollId: 'benefits',
  },
  {
    text: "What's Included",
    scrollId: 'service'
  },
  {
    text: 'FAQ',
    scrollId: 'faq',
    showOnXLOnly: true
  },
  {
    text: "Pricing",
    scrollId: 'pricing'
  }
]