<template>
  <div class="mt-10">
    <h2 class="text-kts-gold text-center text-lg font-medium">UNLEASH YOUR REAL ESTATE POTENTIAL WITH AN AUTOMATED CRM BUILT IN FOLLOW UP BOSS</h2>
    <h1 class="text-white text-4xl text-center mt-5 font-semibold">TRANSFORM YOUR REAL ESTATE BUSINESS <span class="text-kts-gold">WITH<br>KEE TECHNOLOGY SOLUTIONS!</span></h1>
    <div class="max-w-2xl mx-auto mt-10 bg-kts-gold p-2">
      <Video videoId="nROPAowiDCE" />
    </div>
    <div class="flex justify-center mt-10">
      <div class="button-base bg-kts-gold hover-bright" v-scroll-to="{ el: '#book-a-call'}">{{ buttonText }}</div>
    </div>
    <div class="flex justify-between mt-20 flex-wrap">
      <div v-for="{ top, bottom }, idx in callOuts" :key="idx" class="w-full sm:w-1/2 lg:w-60 text-center mt-5 lg:mt-0">
        <p class="text-kts-gold font-bold text-3xl">{{ top }}</p>
        <p class="text-white text-lg font-medium">{{ bottom }}</p>
      </div>
    </div>
    <div class="text-center mt-20 bg-white p-5 rounded-xl">
      <p class="mb-10 text-2xl text-gray-800 font-light">Trusted by these companies:</p>
      <img  v-for="item in [1,2,3,4,5,6,7,8]" :key="`image_${item}`" :src="require(`@/assets/images/workshop/${item}.png`)" class="inline-block w-32 m-2" />
    </div>
    <div class="mt-20 bg-kts-gold p-20 rounded-xl">
      <p class="font-bold text-3xl text-center text-white leading-relaxed">RECEIVE THE DONE-FOR-YOU CRM SETUP IN FOLLOW UP BOSS TRUSTED BY 1200+ REAL ESTATE PROFESSIONALS</p>
      <div class="flex justify-center">
        <div class="button-base bg-white mt-10 hover-bright" v-scroll-to="{ el: '#book-a-call'}">{{ buttonText }}</div>
      </div>
    </div>
    <div class="lg:flex mt-20">
      <div class="lg:w-1/2 lg:pr-3">
        <h2 class="text-kts-gold text-3xl font-semibold">DISCOVER A CRM SYSTEM IN FOLLOW UP BOSS THAT SKYROCKETS YOUR EFFICIENCY</h2>
        <p class="text-white mt-5 text-lg">Imagine a world where managing your leads becomes effortless, where your Follow UP Boss CRM processes run more smoothly, and client relationships are stronger than ever. With Kee Technology Solutions, we turn that world into your reality. Embrace the transformation that countless real estate agents have experienced with us - a streamlined business, massive efficiency, and robust client relationships.</p>
      </div>
      <div class="hidden lg:block lg:w-1/2 lg:pl-3">
        <img :src="require('@/assets/images/fub-people.webp')" />
      </div>
    </div>
    <div class="mt-20 border-t border-b py-10">
      <h2 class="text-3xl font-semibold text-white text-center max-w-3xl mx-auto">START STREAMLINING YOUR OPERATIONS WITH KEE TECHNOLOGY SOLUTIONS TODAY!</h2>
      <div class="flex justify-center">
        <div class="button-base bg-kts-gold mt-10 hover-bright" v-scroll-to="{ el: '#book-a-call'}">SPEAK TO OUR TEAM</div>
      </div>
    </div>
    <div class="flex flex-col lg:flex-row items-center mt-20">
      <div class="w-60 lg:w-1/3 lg:pr-3">
        <img :src="require('@/assets/images/elena-headshot.webp')" />
      </div>
      <div class="mt-5 lg:mt-0 lg:w-2/3 lg:pl-3">
        <h2 class="text-center lg:text-left text-kts-gold text-3xl font-semibold">MEET THE OWNER: ELENA KEE</h2>
        <p class="text-white mt-5 text-lg">Elena Kee organically founded Kee Technology Solutions in 2021 after being a Follow Up Boss user since 2016 and a part of the Follow Up Boss Facebook community for several years. Her company has helped several solo agents, teams, and brokerages in the United States & Canada set up, automate, and simplify their Follow Up Boss processes.</p>
        <p class="text-white mt-5 text-lg">Because of the collaborative nature of her business with real estate agents, Elena is constantly learning new ideas & implementing them for her past clients and new clients to help them succeed.</p>
      </div>
    </div>
    <div class="mt-20 bg-kts-gold p-20 rounded-xl">
      <p class="font-bold text-3xl text-center text-white leading-relaxed">WHY WASTE TIME & MONEY ON INEFFICIENT OPERATIONS?</p>
      <p class="font-bold text-xl text-center text-white leading-relaxed">LET US STREAMLINE YOUR REAL ESTATE BUSINESS FOR YOU.</p>
      <div class="flex justify-center">
        <div class="button-base bg-white mt-10 hover-bright" v-scroll-to="{ el: '#book-a-call'}">SPEAK TO OUR TEAM</div>
      </div>
    </div>
    <div class="mt-20">
      <h3 class="text-kts-gold text-center text-2xl font-medium">SEE REAL RESULTS FROM REAL CLIENTS!</h3>
      <h2 class="text-white text-center text-3xl font-semibold">WE BACK UP OUR CLAIMS WITH PROOF!</h2>
      <Tetimonials :simplified="true" class="mt-10" />
    </div>
    <div class="mt-20 bg-kts-gold p-20 rounded-xl">
      <p class="font-bold text-3xl text-center text-white leading-relaxed">START BUILDING STRONGER CLIENT RELATIONSHIPS!</p>
      <div class="flex justify-center">
        <div class="button-base bg-white mt-10 hover-bright" v-scroll-to="{ el: '#book-a-call'}">SPEAK TO A REAL ESTATE CRM EXPERT, TODAY!</div>
      </div>
    </div>
    <div class="mt-20" id="book-a-call">
      <h2 class="text-white text-center text-3xl font-semibold">BOOK A CALL WITH OUR TEAM, TODAY!</h2>
      <Calendly :url="calendlyUrl" />
    </div>
  </div>
</template>
<script>
import Tetimonials from '../home/Testimonials.vue'
import Calendly from '@/components/common/Calendly';
import { CALENDLY_CALENDARS, CALENDLY_UTM } from '../../../constants'
import { buildCalendarUrl } from '../../../utils'

const callOuts = [
  { top: '1,200+', bottom: 'Clients & Counting!' },
  { top: '30 Days', bottom: 'To Automate Your CRM' },
  { top: '500+', bottom: 'Proven Email Templates With High Response Rates' },
  { top: '30+', bottom: 'Action Plans And Automations' },
]
export default {
  components: { Tetimonials, Calendly },
  props: {
    keeId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      callOuts,
      buttonText: 'SCHEDULE A TIME TO SPEAK WITH OUR TEAM'
    }
  },
  computed: {
    calendlyUrl() {
      return buildCalendarUrl({
        calendar: CALENDLY_CALENDARS.DISCOVERY,
        utm: CALENDLY_UTM.CONVERSIONLY_DISCOVERY(this.keeId)
      })
    }
  }
}
</script>
<style scoped>
.button-base {
  @apply py-5 px-10 cursor-pointer shadow font-medium text-xl text-center
}
</style>import { CALENDLY_UTM } from '../../../constants';import { CALENDLY_UTM } from '../../../constants';

