<template>
  <PromoLayout promo="New Lead Templates" buttonText="Get the templates!">
    <div class="flex justify-center">
      <div class="bg-site-purple text-white font-semibold py-1 px-3 text-sm rounded-full">Swipe & Steal Templates</div>
    </div>
    <div class="text-2xl uppercase text-center mt-5">
      <div>60 TOP PERFORMING</div>
      <div class="bg-black text-white font-semibold py-1 px-3 inline-block mt-2">EMAILS AND TEXTS</div>
      <div class="mt-2">FOR NEW LEADS & NURTURING</div>
    </div>
    <p class="text-lg mt-5">Enter your info below to receive <mark class="bg-purple-300"><strong>60 plug and play templates</strong></mark> for <strong class="font-bold">FREE!</strong></p>
  </PromoLayout>
</template>
<script>
import PromoLayout from '../../layouts/PromoLayout.vue';

export default {
  components: { PromoLayout }
}
</script>