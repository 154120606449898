<template>
  <PromoLayout promo="Fello Automations" buttonText="Get the automations!">
    <div class="text-2xl uppercase text-center">
      <div>Swipe & Steal</div>
      <div class="bg-black text-white font-semibold py-1 px-3 inline-block mt-2">Fello Automations</div>
      <div class="mt-2">for new leads & nurturing</div>
    </div>
    <p class="text-lg mt-5">Enter your info below to receive <mark class="bg-purple-300"><strong>7 plug and play automations</strong></mark> for <strong class="font-bold">FREE!</strong></p>
  </PromoLayout>
</template>
<script>
import PromoLayout from '../../layouts/PromoLayout.vue';

export default {
  components: { PromoLayout }
}
</script>