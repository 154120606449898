<template>
  <div>
    <div v-if="loading">Loading...</div>
    <div v-else-if="noGuide">No guide found!</div>
    <div v-else>
      <div class="bg-gray-800 fixed top-0 left-0 right-0 h-14 text-white z-50 flex items-center justify-center">
        <h1 class="text-2xl font-semibold hidden lg:block">{{ guideName }}</h1>
        <font-awesome-icon icon="fa-solid fa-bars" class="lg:hidden absolute top-4 left-5 text-white text-xl cursor-pointer" @click="toggleNav"/>
      </div>
      <div class="leading-7 overflow-y-scroll fixed top-0 lg:top-14 bottom-0 left-0 w-full lg:w-96 z-50 bg-white" :class="[navOpen ? 'block' : 'hidden lg:block' ]">
        <div class="py-5">
          <div class="flex justify-end pr-5 text-xl p-1 lg:hidden" @click="toggleNav"><font-awesome-icon icon="fa-solid fa-times" /></div>
          <h1 class="lg:hidden px-5 py-2 text-2xl my-5 bg-gray-800 text-white font-semibold text-center">{{guideName}}</h1>
          <div v-for="chapter, idx in chapters" :key="chapter.id" class="px-5" :class="{'mt-5': idx !== 0}">
            <h3 class="font-semibold">{{chapter.title}}</h3>
            <ol class="pl-5">
              <li
                v-for="segment in chapter.segments"
                :key="segment.id" class="cursor-pointer mt-2 hover:text-green-700"
                :class="{'text-green-700 flex font-semibold': segment.id === selectedSegment.id }"
                @click="showSegment(segment)"
              >
                <div v-if="segment.id === selectedSegment.id" class="w-1 bg-green-700 mr-2"></div>
                <div>{{ `${segment.title}` }}</div>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="container pt-14 xl:mx-auto lg:pl-96">
        <div class="p-5">
          <h2 class="text-2xl mb-5 font-semibold">{{ selectedSegment.title }}</h2>
          <ContentfulRTE :html="selectedSegment.html" />
          <div class="flex my-10" :class="[selectedSegmentIdx > 0 ? 'justify-between' : selectedSegmentIdx + 1 < flatSegments.length ? 'justify-end' : '']">
            <a class="text-green-700 border border-green-700 cursor-pointer py-2 px-5 rounded-full hover:bg-green-100 transition-all font-semibold" v-if="selectedSegmentIdx > 0" @click="showPrevious"><font-awesome-icon icon="fa-solid fa-arrow-left" /> Previous</a>
            <a class="text-green-700 border border-green-700 cursor-pointer py-2 px-5 rounded-full hover:bg-green-100 transition-all font-semibold" v-if="selectedSegmentIdx + 1 < flatSegments.length" @click="showNext">Next <font-awesome-icon icon="fa-solid fa-arrow-right" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { videoHtml } from '@/utils'
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import _get from 'lodash/get'

const convertToHtml = (document) => documentToHtmlString(
  document,
  {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const targetData = _get(node, 'data.target')
        
        if (_get(targetData, 'sys.contentType.sys.id') !== 'video') {
          return ''
        }

        const videoId = targetData.fields.videoIdentifier

        const videoType = targetData.fields.type

        return videoHtml(videoId, videoType)
      }
    }
  }
)

const getChapters = (chapters) => chapters.map((chapter) => ({
  id: chapter.sys.id,
  title: chapter.fields.title,
  segments: getSegments(chapter.fields.guideChapterSegments || [])
}))

const getSegments = (segments) => segments.map((segment) => ({
  id: segment.sys.id,
  title: segment.fields.title,
  html: convertToHtml(segment.fields.segmentContent)
}))

export default {
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      noGuide: false,
      guideName: '',
      chapters: [],
      navOpen: false,
      selectedSegment: {
        id: '',
        title: '',
        html: ''
      }
    }
  },
  async created() {
    this.loading = true
    try {
      const entriesData = await this.$contentful.getEntries({
        limit: 1,
        content_type: 'guide',
        'fields.slug': this.slug,
        include: 10
      })
      
      this.loading = false
      if (entriesData.total === 0) {
        this.noGuide = true
        return
      }

      const entry = entriesData.items[0]

      this.guideName = entry.fields.title
      this.chapters = getChapters(entry.fields.guideChapters)
      const segment = this.chapters[0].segments[0]

      this.showSegment(segment)

    } catch (error) {
      this.loading = false
      console.error(error)
    }
  },
  computed: {
    flatSegments() {
      return this.chapters.reduce((acc, chapter) => acc.concat(chapter.segments), [])
    },
    selectedSegmentIdx() {
      this.selectedSegment // this is simply referenced to force an update on this computed property - there was an issue with segments moving in the navigation after clicking next/prev and then manaully selecting a segment from navigation
      return this.flatSegments.findIndex(seg => seg.id === this.selectedSegment.id)
    }
  },
  methods: {
    showSegment(segment) {
      if (this.selectedSegment.id !== segment.id) {
        if (this.navOpen) {
          this.navOpen = false
        }
        window.scrollTo(0, 0)
        this.selectedSegment = segment
      }
    },
    toggleNav() {
      this.navOpen = !this.navOpen
    },
    showPrevious() {
      window.scrollTo(0, 0)
      this.selectedSegment = this.flatSegments[this.selectedSegmentIdx - 1]
    },
    showNext() {
      window.scrollTo(0, 0)
      this.selectedSegment = this.flatSegments[this.selectedSegmentIdx + 1]
    }
  }
}
</script>