<template>
  <section class="py-20 px-6 bg-site-gray text-white" id="faq">
    <div class="container mx-auto">
      <h3 class="text-center text-4xl font-bold">Frequently Asked Questions</h3>
      <div class="mt-20 leading-loose font-semibold">
        <FAQItem v-for="{ question, answer }, idx in faqList" :key="`faq_${idx}`" :class="{ 'border-t': idx > 0 }" :question="question" :answer="answer" />
      </div>
    </div>
  </section>
</template>
<script>
import FAQItem from './FAQItem.vue'
export default {
  props: ['faqList'],
  components: {
    FAQItem
  }
}
</script>