<template>
  <StandardPageLayout>
    <div class="flex justify-center">
      <button v-for="(title, idx) in tabTitles" :key="idx" @click="selectTab(idx)" class="text-xl px-5 py-2 focus:outline-none transition-all ease-in-out duration-500 rounded-lg" :class="{ 'bg-kts-gold text-white font-semibold': selectedTabIdx === idx, 'hover:underline': selectedTabIdx !== idx }">{{ title }}</button>
    </div>
    <div class="mt-10">
      <Library v-if="selectedTabIdx === 0" :videoLists="videoLists" :selectedVideo="selectedVideo" @show="show" />
      <FAQ v-else :faqList="faqList" />
    </div>
  </StandardPageLayout>
</template>
<script>
import Library from './Library.vue'
import FAQ from './FAQ.vue'
import getOr from 'lodash/fp/getOr'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
export default {
  components: { Library, FAQ },
  props: {
    slug: {
      type: String,
      required: true 
    }
  },
  data() {
    return {
      title: '',
      faqList: [],
      videoLists: [],
      selectedVideo: {},
      selectedTabIdx: 0
    }
  },
  async created() {
    // this.loading = true
    try {
      const entries = await this.$contentful.getEntries({
        content_type: 'training',
        include: 10,
        'fields.slug': this.slug,
      })

      const { title, faqList, videoLists } = getOr({}, 'items[0].fields')(entries)

      this.title = title

      this.faqList = faqList.map((faq) => {
        const { question, answer } = getOr({}, 'fields')(faq)
        return {
          question,
          answer: documentToHtmlString(answer)
        }
      })

      this.videoLists = videoLists.map((videoList) => {
        const { title, list } = getOr({}, 'fields')(videoList)
        return {
          title,
          videos: list.map((video) => getOr({}, 'fields')(video))  
        }
      })

      this.selectedVideo = getOr('', '[0].videos[0]')(this.videoLists)
      
      // this.loading = false
    } catch (error) {
      // this.loading = false
      console.error(error)
    }
  },
  computed: {
    tabTitles: () => ['Video Library', 'FAQ']
  },
  methods: {
    selectTab(idx) {
      if (idx !== this.selectedTabIdx) {
        this.selectedTabIdx = idx
      }
    },
    show(video) {
      if (this.selectedVideo.videoIdentifier !== video.videoIdentifier) {
        this.selectedVideo = video
      }
    }
  }
}
</script>